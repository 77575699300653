import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select'
import { useSelector } from 'react-redux';
import { SVGAllYear, SVGWinter, SVGSummer, SVGAllTires, SVGClearFields } from '../svg';

function SearchSelect({ label, value, options, placeholder, unit, callback, hideClear }) {
    if (options == undefined) {
        options = []
    }

    if (hideClear == undefined) {
        hideClear = false
    }

    let icons = {
        'Öll dekk': <SVGAllTires />,
        'Vetrardekk': <SVGWinter />,
        'Sumardekk': <SVGSummer />,
        'Heilsársdekk': <SVGAllYear />
    }

    const selectInputRef = useRef(); 
    let selectoptions = options.map((option, index) => {
            if (Array.isArray(option)) {
                return { value: option[0], label: option[1], icon: icons[option[1]] || null }
            }
            return { value: option, label: option }
        })

    let selectedValue = selectoptions.find((option) => option.value == value)
    return (
        <div className='position-relative'>
            <label>{label}</label>
            { !hideClear && selectedValue !== "" && selectedValue !== null && selectedValue !== undefined &&
                <span onClick={() => { callback("") }} className="clear cursor-pointer font-sm position-absolute"><SVGClearFields /></span>
            }
            <Select
                key={`${label}_${selectedValue}`}
                ref={selectInputRef}
                options={selectoptions}
                value={selectedValue}
                classNamePrefix={'react'}
                onChange={(e) => callback(e.value)}
                getOptionLabel={e => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {e.icon}
                      <span style={{ marginLeft: 5 }} className="value">{e.label}</span>
                    </div>
                )}
                placeholder={placeholder}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: value && '#fff',
                        borderColor: state.isFocused ? '#47535D' : '#8797A4',
                        boxShadow: state.isFocused || state.isSelected && 'none',
                        cursor: 'pointer',
                        outline: state.isSelected || state.isFocused && 'none',
                        padding: '6px 0',
                        "&:hover": {
                            borderColor: "#8797A4"
                        },
                    }),
                    option: (baseStyles) => ({
                        ...baseStyles,
                        cursor: 'pointer',
                    }),
                    singleValue: (baseStyles) => ({
                        ...baseStyles,
                        fontWeight: '600',
                        color: '#47535D'
                    }),
                    indicatorSeparator: (baseStyles) => ({
                        ...baseStyles,
                        display: 'none'
                    }),
                    dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        color: '#8797A4',
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: '#47535D',
                        fontWeight: '600'
                    }),
                }}
            />
        </div>
    )
}

SearchSelect.defaultProps = {
    placeholder: "Veldu",
}


export default SearchSelect;