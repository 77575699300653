import React, { useEffect} from 'react'
import { useDispatch } from 'react-redux'
import Userbar from '../../components/userbar'
import { getCurrentUser } from '../../slices/users';
import NotLoggedInModal from '../../components/not-logged-in-modal';

export default function UserbarContainer(props) {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getCurrentUser())
    }, [])

    return (
        <>
        <Userbar />
        <NotLoggedInModal />
        </>
    )
  }