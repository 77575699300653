import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/js/bootstrap.min.js";
import '../sass/main.scss';

require ('./containers/frontpagesearch-container/index.js');
require ('./containers/webshop-container/index.js');
require ('./containers/userbar-container/index.js');
require ('./containers/tire-block-container/index.js');
require ('./containers/cart-details-container/index.js');
require ('./containers/minar-sidur-container/index.js');
require ('./containers/tire-calculator-container/index.js');