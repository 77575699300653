import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import CartDetails from '../../components/cart_details';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <CartDetails {...props}/>
        </Provider>
    )
}