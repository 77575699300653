import axios from "axios";
import { getCookie, convertArgMapListToQueryString } from "../../utils";
import { API_BASE_PATH } from "../../const";

const getTires = (args) => {
    return axios
        .get(API_BASE_PATH + `tires/${args}`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};


const getTireDetail = (args) => {
    
    return axios
        .get(API_BASE_PATH + `tires/${args.tireId}/`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const getTireParams = (args) => {
    
    return axios
        .get(API_BASE_PATH + `tires/params/`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const tireService = {
    getTires,
    getTireDetail,
    getTireParams,
};

export default tireService;