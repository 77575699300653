import React from 'react';
import { Provider } from 'react-redux'
import { store } from '../../store'
import FrontpageSearchContainer from './frontpagesearch-container';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <FrontpageSearchContainer {...props}/>
        </Provider>
    )
}