import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CartService from "../../api/cart";


export const getCart = createAsyncThunk(
    "cart/get",
    async (args, thunkAPI) => {
        try {
            const response = await CartService.getCart(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const addToCart = createAsyncThunk(
    "cart/post",
    async (args, thunkAPI) => {
        try {
            const response = await CartService.addToCart(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const orderCart = createAsyncThunk(
    "cart/order",
    async (args, thunkAPI) => {
        try {
            const response = await CartService.orderCart(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateCartLineQuantity = createAsyncThunk(
    "cart/patch",
    async (args, thunkAPI) => {
        try {
            const response = await CartService.updateCartLineQuantity(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const removeCartLine = createAsyncThunk(
    "cart/delete",
    async (args, thunkAPI) => {
        try {
            const response = await CartService.removeCartLine(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    cart: null,
    loading: false,
    adding: false,
    loadingQty: false,
    cartOrdered: false,
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    extraReducers: {
        [getCart.pending]: (state, action) => {
            state.loading = true;
        },
        [getCart.fulfilled]: (state, action) => {
            state.cart = action.payload;
            state.loading = false;
        },
        [getCart.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja körfu";
            state.loading = false;
        },
        [addToCart.pending]: (state, action) => {
            state.adding = true;
            state.loading = true;
        },
        [addToCart.fulfilled]: (state, action) => {
            state.cart = action.payload;
            state.adding = false;
            state.loading = false;
        },
        [addToCart.rejected]: (state, action) => {
            state.error = "Ekki tókst að bæta í körfu";
            state.adding = false;
            state.loading = false;
        },
        [updateCartLineQuantity.pending]: (state, action) => {
            state.loadingQty = true;
        },
        [updateCartLineQuantity.fulfilled]: (state, action) => {
            state.cart = action.payload;
            state.loadingQty = false;
        },
        [updateCartLineQuantity.rejected]: (state, action) => {
            state.error = "Ekki tókst að uppfæra magn í körfu";
            state.loadingQty = false;
        },
        [removeCartLine.pending]: (state, action) => {
            state.loading = true;
        },
        [removeCartLine.fulfilled]: (state, action) => {
            state.cart = action.payload;
            state.loading = false;
        },
        [removeCartLine.rejected]: (state, action) => {
            state.error = "Ekki tókst að eyða línu úr körfu";
            state.loading = false;
        },
        [orderCart.pending]: (state, action) => {
            state.loading = true;
        },
        [orderCart.fulfilled]: (state, action) => {
            state.cart = action.payload;
            state.loading = false;
            state.cartOrdered = true;
        },
        [orderCart.rejected]: (state, action) => {
            state.error = "Ekki tókst að panta körfu";
            state.loading = false;
        },
    },
});

const { reducer } = cartSlice;
export default reducer;