import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import TireblockContainer from './tireblock-container';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <TireblockContainer {...props}/>
        </Provider>
    )
}