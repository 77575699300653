import React, { useState, useEffect } from 'react';
import { SVGRecycleBin, SVGMinus, SVGPlus } from '../svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCart, removeCartLine, updateCartLineQuantity, orderCart } from '../../slices/cart';
import { formatNumber } from '../../utils';
import { useForm, Controller } from 'react-hook-form';


function CartDetails() {
    const dispatch = useDispatch()
    const cartObj = useSelector(state => state.cart || {})
    const cart = useSelector(state => state.cart.cart || [])
    const { handleSubmit, control, watch, formState: { errors } } = useForm();
    const deliveryMethod = watch('delivery');

    const [loadingQty, setLoadingQty] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(null);

    const [cartOrdered, setCartOrdered] = useState(false);

    const removeFromCart = (id) => {
        setLoadingDelete(id)
        Promise.resolve(dispatch(removeCartLine({ "line_id": id })))
            .then(() => {
                setLoadingDelete(null)
            })
    }

    const onSubmit = (data) => {
        Promise.resolve(dispatch(orderCart({ shipping: data})))
            .then(() => {
                setCartOrdered(true)
            })
    };

    const updateQuantity = (id, etag, quantity) => {
        setLoadingQty(id)
        Promise.resolve(dispatch(updateCartLineQuantity({ "line_id": id, "etag": etag, "quantity": quantity })))
            .then(() => {
                setLoadingQty(null)
            })
    }

    useEffect(() => {
        dispatch(getCart());
    }, []);

    return (
        <>
            {cartOrdered ?
                <>
                    <h1 class="border-bottom pb-3 mb-5">Takk fyrir að velja Mítra</h1>
                    <div>
                        <p>
                            Við erum fara yfir- og taka saman pöntunina þína.
                        </p>
                        <p>
                            Ef þú hefur valið að fá dekkin send, getur þú búist við að þau verði send með næstu mögulegu ferð.
                            Þú getur einnig nálgast pöntunina þína á opnunartíma Mítra.
                        </p>
                        <p>
                            Ef þú hefur einhverjar spurningar eða þarft frekari upplýsingar, ekki hika við að hafa samband í síma <a class="link" href="tel:554 1517">554 1517</a>.
                        </p>

                        <p>Kveðjur,<br />
                            Þjónustudeild Mítra</p>
                    </div>
                </>
                :
                <>
                    <h1 class="border-bottom pb-3">Karfa</h1>
                    <table className="table">
                        <thead>
                            <tr className='d-none d-md-table-row'>
                                <th scope="col">Vara</th>
                                <th scope="col" className='text-end'>Verð</th>
                                <th scope="col" className='text-end'>Afsláttur</th>
                                <th scope="col">Fjöldi</th>
                                <th scope="col" className='text-end'>Alls</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cart.map((item, index) => {
                                return (
                                    <>
                                        <tr key={`item-${index}-mobile`} className='d-table-row d-md-none'>
                                            <td className='mobile'>
                                                <div className='d-flex flex-wrap border-bottom pb-4'>
                                                    <div className='d-flex w-100 justify-content-between mb-3'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='img-container d-inline-block me-3'><img src={item.product.image} /></div>
                                                            <div>
                                                                {item.product.title}
                                                                <span className='d-block'>{formatNumber(item.netAmountIncludingTax / item.quantity)} kr.</span>
                                                            </div>
                                                        </div>
                                                        <div className='bin'>
                                                            <button className="btn btn-clear bin" onClick={() => removeFromCart(item.id)}>
                                                                {loadingDelete === item.id ?
                                                                    <div className="spinner-border" role="status" />
                                                                    :
                                                                    <SVGRecycleBin />
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex w-100 justify-content-between align-items-center'>
                                                        <div className='d-flex align-items-center justify-content-between price-container mt-auto'>

                                                            <div key={`item-${item.id}-${item.quantity}`} className='quantity-counter d-flex align-items-center justify-content-between'>
                                                                <div className="svg-container" onClick={() => item.quantity > 1 && updateQuantity(item.id, item["@odata.etag"], item.quantity - 1)}>
                                                                    <SVGMinus />
                                                                </div>
                                                                {loadingQty === item.id ?
                                                                    <div className="spinner-border" role="status" />
                                                                    :
                                                                    <input type="text" value={item.quantity} onChange={(e) => e.target.value === "" ? updateQuantity(item.id, item["@odata.etag"], 0) : updateQuantity(item.id, item["@odata.etag"], parseInt(e.target.value))} />
                                                                }
                                                                <div className="svg-container" onClick={() => updateQuantity(item.id, item["@odata.etag"], item.quantity + 1)}>
                                                                    <SVGPlus />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {item.discountPercent > 0 && <span className='d-block font-sm'>Afsláttur {item.discountPercent}%</span>}
                                                            <span>{formatNumber(item.netAmountIncludingTax)} kr.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr key={`item-${index}`} className='d-none d-md-table-row'>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='img-container d-inline-block me-3'><img src={item.product.image} /></div>
                                                    <div>
                                                        {item.product.title}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='text-end text-nowrap'>{formatNumber(item.netAmountIncludingTax / item.quantity)} kr.</td>
                                            <td className='text-end text-nowrap'>{item.discountPercent}%</td>
                                            <td>
                                                <div className='d-flex align-items-center justify-content-between price-container mt-auto'>

                                                    <div className='quantity-counter d-flex align-items-center justify-content-between'>
                                                        <div className="svg-container" onClick={() => item.quantity > 1 && updateQuantity(item.id, item["@odata.etag"], item.quantity - 1)}>
                                                            <SVGMinus />
                                                        </div>
                                                        {loadingQty === item.id ?
                                                            <div className="spinner-border" role="status" />
                                                            :
                                                            <input type="text" value={item.quantity} onChange={(e) => e.target.value === "" ? updateQuantity(item.id, item["@odata.etag"], 0) : updateQuantity(item.id, item["@odata.etag"], parseInt(e.target.value))} />
                                                        }
                                                        <div className="svg-container" onClick={() => updateQuantity(item.id, item["@odata.etag"], item.quantity + 1)}>
                                                            <SVGPlus />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='text-end text-nowrap'>{formatNumber(item.netAmountIncludingTax)} kr.</td>
                                            <td className='bin'>
                                                <button className="btn btn-clear bin" onClick={() => removeFromCart(item.id)}>
                                                    {loadingDelete === item.id ?
                                                        <div className="spinner-border" role="status" />
                                                        :
                                                        <SVGRecycleBin />
                                                    }
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="cart-details-footer d-flex justify-content-between ms-auto">
                        <div className="labels mb-4 d-flex flex-column justify-content-around">
                            <div className="total-label bold">Samtals</div>
                            <div className="VAT-label semibold">Þar af VSK</div>
                        </div>
                        <div className="amounts">
                            <div className="total-price bold text-end">{formatNumber(cart.reduce((a, b) => a + (b.netAmountIncludingTax), 0))} kr.</div>
                            <div className="VAT-price semibold text-end">{formatNumber(cart.reduce((a, b) => a + (b.netTaxAmount), 0))} kr.</div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className='delivery'>
                        <div className="row">
                            <div className="col-sm-5 col-16">
                                <h5 className='mb-3 mb-sm-5 pb-2 border-bottom'>Tilvísun</h5>
                                <label className="form-label w-100 mb-4">
                                    Tilvísunarnúmer
                                    <Controller
                                        name="reference"
                                        control={control}
                                        render={({ field }) => <input maxlength="35" className="form-control mt-2 mb-1" {...field} />}
                                    />
                                </label>
                                <label className="form-label w-100">
                                    Aðrar upplýsingar
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) => <textarea rows="5" className="form-control mt-2 mb-1" {...field} />}
                                    />
                                </label>
                            </div>
                            <div className="mt-5 mt-sm-0 col-16 col-sm-10 offset-sm-1">
                                <h5 className='mb-3 pb-2 border-bottom'>Afhending</h5>
                                <div className="row">
                                    <Controller
                                        name="delivery"
                                        control={control}
                                        defaultValue="Sótt"
                                        render={({ field }) => (
                                            <>
                                                <div className="col-md-7 col-8 position-relative d-flex align-items-center radio-container">
                                                    <input {...field} type="radio" id="picked_up" value="Sótt" checked={deliveryMethod === 'Sótt'} />
                                                    <label htmlFor="picked_up">Sótt</label>
                                                </div>
                                                <div className="col-md-8 offset-md-1 col-8 position-relative d-flex align-items-center radio-container">
                                                    <input {...field} type="radio" id="sent" value="Sent" checked={deliveryMethod === 'Sent'} />
                                                    <label htmlFor="sent">Sent</label>
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="row mt-3">
                                    {deliveryMethod === 'Sótt' && (
                                        <div className="col-16">
                                            <p className='bold mb-0'>Pöntun sótt á vörulager Mítra</p>
                                            <p className='mb-4'>Tunguhálsi 15, 110 Reykjavík</p>
                                            <p className='bold mb-0'>Afgreiðslutímar</p>
                                            <p className='mb-4'>Mánudagar til fimmtudagar 8 - 17<br />
                                                Föstudagar 8-16<br />
                                                Lokað um helgar
                                            </p>
                                        </div>
                                    )}
                                    {deliveryMethod === 'Sent' && (
                                        <div className="col-16">
                                            <>
                                                <div className="row">
                                                    <div className="col-16">
                                                        <label className="form-label w-100 mb-4">
                                                            Viðtakandi
                                                            <Controller
                                                                name="shipToName"
                                                                control={control}
                                                                rules={{ required: "Slá þarf inn nafn viðtakanda" }}
                                                                render={({ field }) => <input className="form-control mt-2 mb-1" {...field} />}
                                                            />
                                                            {errors.shipToName && <p className='error'>{errors.shipToName.message}</p>}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-7 col-16">
                                                        <label className="form-label w-100">
                                                            Heimilisfang
                                                            <Controller
                                                                name="shipToAddressLine1"
                                                                control={control}
                                                                rules={{ required: "Slá þarf inn heimilisfang" }}
                                                                render={({ field }) => <input className="form-control mt-2 mb-1" {...field} />}
                                                            />
                                                            {errors.shipToAddressLine1 && <p className='error'>{errors.shipToAddressLine1.message}</p>}
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-3 col-8">
                                                        <label className="form-label">
                                                            Póstnr.
                                                            <Controller
                                                                name="shipToPostCode"
                                                                control={control}
                                                                rules={{ required: "Slá þarf inn nafn póstnúmer" }}
                                                                render={({ field }) => <input className="form-control mt-2 mb-1" {...field} />}
                                                            />
                                                            {errors.shipToPostCode && <p className='error'>{errors.shipToPostCode.message}</p>}
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 col-8">
                                                        <label className="form-label w-100">
                                                            Staður
                                                            <Controller
                                                                name="shipToCity"
                                                                control={control}
                                                                rules={{ required: "Slá þarf inn stað" }}
                                                                render={({ field }) => <input className="form-control mt-2 mb-1" {...field} />}
                                                            />
                                                            {errors.shipToCity && <p className='error'>{errors.shipToCity.message}</p>}
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-16">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-dark btn-lg float-end">Staðfesta pöntun</button>
                                </div>
                        </div>
                    </form>
                </>
            }
        </>
    );
}

export default CartDetails;