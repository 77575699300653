import axios from "axios";
import { getCookie } from "../../utils";
import { API_BASE_PATH } from "../../const";

const getInvoices = () => {
    return axios
        .get(API_BASE_PATH + `invoices/`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const invoicesService = {
    getInvoices,
};

export default invoicesService;