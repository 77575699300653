import React, { useEffect } from 'react'
import {
  useTable, useSortBy, usePagination, useFilters
} from 'react-table'
import PropTypes from 'prop-types'
import Paginator from '../paginator'

const Table = ({ columns, data, size, onRowClick, selectedRow, paginate, loading, totalRows, pageChangeHandler, pagePage, gotoPagePage, initialSort, setSortBy = () => { } }) => {

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {

    return null
  }
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {
      sortBy,
      pageIndex,
      pageSize,
      filters,
    },
    pageOptions,
    page,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable({
    columns,
    data,
    defaultColumn,
    initialState: {
      sortBy: initialSort
    },
  },
    useFilters,
    useSortBy,
    usePagination,
  )

  useEffect(() => {
    setSortBy(sortBy)
  }, [sortBy])

  return (
    <>
      <div className='table-container'>
        <table className={`table table-hover table-${size}`} {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps([{ className: column.className }])}>
                    <span className='filter' {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <div className='down'></div>
                          : <div className='up'></div>
                        : ''}
                    </span>
                    <span className='filter'>{column.canFilter ? column.render('Filter') : null}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>

            {page.map((row, i) => {
              prepareRow(row)
              const extraClass = "" + row.original.id === selectedRow ? 'active' : ''
              return (
                <tr {...row.getRowProps({ className: extraClass })} onClick={() => onRowClick ? onRowClick(row) : false}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps([
                      { className: cell.column.className }
                    ])}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}

          </tbody>
        </table>
      </div>

      {paginate &&
        <>
          <Paginator
            numResults={rows.length}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            numPages={pageOptions.length}
            showPageSizeSelector
          />
        </>
      }


    </>
  )
}

export default Table

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  size: PropTypes.string,
  loading: PropTypes.bool,
  initialSort: PropTypes.array,
  paginate: PropTypes.bool,
};

Table.defaultProps = {
  columns: [],
  data: [],
  size: 'md',
  loading: false,
  initialSort: [],
  paginate: false,
};



// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">Allar stöður</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// Define a simple UI for filtering
export const TextSearchFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder="Sía"
    />
  )
}