import React, { useEffect} from 'react'
import { useDispatch } from 'react-redux'
import FrontpageSearch from '../../components/frontpage_search'
import { getTireParams } from '../../slices/tires';

export default function FrontpageSearchContainer(props) {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getTireParams())
    }, [])

    return (
        <FrontpageSearch />
    )
  }