import accounting from 'accounting';
import moment from 'moment';

import { API_DEFAULT_PAGE_SIZE } from './const';

export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) == (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const getAnchor = () => {
  return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
}

export const setAnchor = (anchor) => {
  window.location.hash = anchor;
}

export const removeAnchor = () => {
  history.replaceState({}, document.title, ".");
}

export const invoiceDateFormat = (date) => {
  return moment(date).format('D.M.yyyy')
}

export const formatNumber = (num) => {
  return accounting.formatMoney(+num, '', 0, ".", ",")
}

export const GetQueryStringParams = (sParam) => {
  var sPageURL = window.location.search.substring(1);
  var sURLVariables = sPageURL.split('&');
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] == sParam) {
      return sParameterName[1];
    }
  }
}


export const convertErrorsFromServer = (errors) => {
  var _errors = []
  Object.keys(errors).map(key => {
    _errors.push({ type: "manual", name: key, message: errors[key].join(" ") })
  })
  return _errors
}


export const convertPageToOffsetLimit = (page, pageSize = API_DEFAULT_PAGE_SIZE) => {

  let offset = 0
  if (page > 1) {
    offset = (page - 1) * pageSize
  }
  return [{ offset: offset }, { limit: pageSize }]

}

export const convertSortBy = (sortBy) => {
  if (sortBy && sortBy.length > 0) {
    return sortBy.map((sort) => {
      return { 'ordering': `${sort.desc ? '-' : ''}${sort.id.replace(".", "__")}` }
    })
  }
  return []
}

export const convertArgMapListToQueryString = (args) => {
  let paramsString = '?'
  if (args !== undefined) {
    for (var j = 0; j < args.length; j++) {
      let [k, v] = Object.entries(args[j])[0]
      if (Array.isArray(v) && v.every((element) => element instanceof Date) && v.length === 2) {
        paramsString += `${k}_after=${v[0].toISOString().substring(0, 10)}&${k}_before=${v[1].toISOString().substring(0, 10)}&`
      } else {
        paramsString += `${k}=${v}&`
      }
    }
  }
  return paramsString
}

export const formatPercentage = (num, extra = 0) => {
  return `${accounting.formatMoney(+num, '', extra, ".", ",")}%`
}

export const getInvoiceStatusDescriptive = (status) => {
  switch (status) {
    case "Paid":
      return "Greitt";
    case "Open":
      return "Opinn";
    case "Canceled":
      return "Hætt við";
    case "Draft":
      return "Í vinnslu";
    default:
      return status;
  }
}

export const getOrderStatusDescriptive = (status) => {
  switch (status) {
    case "Draft":
      return "Í vinnslu";
    default:
      return status;
  }
}

export const removeEmpty = (obj) => {
  console.log(obj)
  let a = Object.keys(obj)
    .filter(function (k) {
      return obj[k] !== "";
    })
    .reduce(function (acc, k) {
      acc[k] = obj[k];
      return acc;
    }, {});
  console.log("filtered", a)
  return a
  }
