import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import tireReducer from './slices/tires';
import userReducer from './slices/users';
import cartReducer from './slices/cart';
import orderReducer from './slices/orders';
import invoiceReducer from './slices/invoices';
import modalReducer from './slices/modal';
import discountReducer from './slices/discounts';

const loggerMiddleware = createLogger()

const reducers = combineReducers({
    tires: tireReducer,
    users: userReducer,
    cart: cartReducer,
    invoices: invoiceReducer,
    orders: orderReducer,
    modals: modalReducer,
    discounts: discountReducer,
})

let middleware = null
if (process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
        thunkMiddleware,
    )
} else {
    middleware = composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
    ))
}

export const store = createStore(reducers, middleware)
