import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../product_card';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SVGInfo } from '../svg';

function TireList({ tires }) {

    const dispatch = useDispatch()
    const [quantity, setQuantity] = useState(1);

    return (
        <>
            {tires.map((tire, index) => {
                return (
                    <div className="col-16 col-lg-4 col-md-8 tire-card-container" key={index}>
                        <ProductCard product={tire} />
                    </div>
                )
            })}
        </>
    )

}

export default TireList;
