import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SVGClearFields } from '../svg';


function SearchLicensePlate({ value, callback, clearCallback, hideClear }) {

    const [state, setState] = useState(value)
    const tireObj = useSelector((state) => state.tires);

    if (hideClear == undefined) {
        hideClear = false
    }

    return (
        <div className="search-license-plate">
            <label htmlFor="license-plate">{state !== "" && tireObj.error ? 'Bílnúmer finnst ekki' : 'Sláðu inn bílnúmer'}</label>
            <div className='position-relative'>
                {hideClear && state !== "" && state !== null && state !== undefined &&
                    <span onClick={() => { callback("") }} className="clear cursor-pointer font-sm position-absolute"><SVGClearFields /></span>
                }
                <form className='d-flex' onSubmit={(e) => { e.preventDefault(); callback(state) }}>
                    <input
                        value={state}
                        id="license-plate"
                        className={`form-control w-50 me-3 text-center ${state !== "" && tireObj.error ? 'error' : ''}`}
                        onChange={(e) => setState(e.target.value)}
                    />
                    <button className="btn col btn-dark" type="submit">Áfram</button>
                </form>
            </div>
        </div>
    )
}

export default SearchLicensePlate;