import axios from "axios";
import { getCookie } from "../../utils";
import { API_BASE_PATH } from "../../const";

const getCart = () => {
    return axios
        .get(API_BASE_PATH + `cart/`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const addToCart = (args) => {
    return axios
        .post(API_BASE_PATH + `cart/add/`, {
            ...args
        }, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const updateCartLineQuantity = (args) => {
    return axios
        .patch(API_BASE_PATH + `cart/${args.line_id}/`, {
            'etag': args.etag,
            'quantity': args.quantity
        }, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const removeCartLine = (args) => {
    return axios
        .delete(API_BASE_PATH + `cart/${args.line_id}/`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const orderCart = (args) => {
    return axios
        .post(API_BASE_PATH + `cart/order/`, {
            ...args
        }, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
}

const cartService = {
    getCart,
    addToCart,
    removeCartLine,
    updateCartLineQuantity,
    orderCart
};

export default cartService;