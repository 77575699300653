import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TireService from "../../api/tires";


export const getTires = createAsyncThunk(
  "tires/get",
  async (args, thunkAPI) => {
    try {
      const response = await TireService.getTires(args);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTireBlockTires = createAsyncThunk(
  "tireblock/get",
  async (args, thunkAPI) => {
    try {
      const response = await TireService.getTires(args);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTireDetail = createAsyncThunk(
  "tireDetail/get",
  async (args, thunkAPI) => {
    try {
      const response = await TireService.getTireDetail(args);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTireParams = createAsyncThunk(
  "tireparams/get",
  async (id, thunkAPI) => {
    try {
      const response = await TireService.getTireParams(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setSelectedTire = createAsyncThunk(
  "tire/set",
  async (tire, thunkAPI) => {
    try {
      return tire;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


const initialState = {
  tireList: [],
  selectedTire: null,
  loading: false,
  loadingDetail: false,
  tireBlockList: [],
};

const tiresSlice = createSlice({
  name: "tires",
  initialState,
  extraReducers: {
    [getTires.pending]: (state, action) => {
      state.loading = true;
    },
    [getTires.fulfilled]: (state, action) => {
      state.error = '';
      state.tireList = action.payload;
      state.loading = false;
    },
    [getTires.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja dekk";
      state.loading = false;
    },
    [getTireBlockTires.pending]: (state, action) => {
      state.loading = true;
    },
    [getTireBlockTires.fulfilled]: (state, action) => {
      state.tireBlockList = action.payload;
      state.loading = false;
    },
    [getTireBlockTires.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja dekk";
      state.loading = false;
    },
    [getTireDetail.pending]: (state, action) => {
      state.loadingDetail = true;
    },
    [getTireDetail.fulfilled]: (state, action) => {
      state.selectedTire = action.payload;
      state.loadingDetail = false;
    },
    [getTireDetail.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja upplýsingar um dekk";
      state.loadingDetail = false;
    },
    [getTireParams.pending]: (state, action) => {
      state.loading = true;
    },
    [getTireParams.fulfilled]: (state, action) => {
      state.params = action.payload;
      state.loading = false;
    },
    [getTireParams.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja dekkjastærðir";
      state.loading = false;
    },
    [setSelectedTire.pending]: (state, action) => {
      state.loading = true;
    },
    [setSelectedTire.fulfilled]: (state, action) => {
      state.selectedTire = action.payload;
      state.loading = false;
    },
    [setSelectedTire.rejected]: (state, action) => {
      state.error = "Ekki tókst að setja valið dekk";
      state.loading = false;
    },
  },
});

const { reducer } = tiresSlice;
export default reducer;