import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import TireCalculator from '../../components/tire_calculator'

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <TireCalculator {...props} />
        </Provider>
    )
}