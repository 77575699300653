import React from "react"
import Modal from "../modal"
import { MODAL_NOT_LOGGED_IN } from "../../const"


const NotLoggedInModal = () => {

    return (
        <Modal
            id={MODAL_NOT_LOGGED_IN}
            title="Innskráning"
        >
            <p className="mb-4">Ef þú ert þegar í viðskiptum við okkur getur þú skráð þig inn til þess að senda inn pöntun.</p>
            <p>Ef þú ert þegar í viðskiptum en ert ekki með aðgang að vefverslun getur þú sótt um aðgang í næsta skrefi með því að smella á hnappinn hér að neðan.</p>
            <p> Ef þú ert ekki í viðskiptum er hægt að sækja um reikningsviðskipti í næsta skrefi með því að smella á hnappinn hér að neðan.</p>
            <p>Hafir þú  spurningar er best að senda póst á <a class="link" href="mailto:mitra@mitra.is">mitra@mitra.is</a> eða hringja í síma <a class="link" href="tel:5541517">554-1517</a></p>
            <div className="d-flex justify-content-between mt-5">
                <a className="btn btn-primary col me-2 d-flex align-items-center justify-content-center" href="/login/">Innskráning / Nýskráning</a>
            </div>
        </Modal>
    )
}

export default NotLoggedInModal