import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchSelect from '../../components/search_select';
import SearchLicensePlate from '../../components/search_license_plate';
import { getTireParams, getTires, getTireDetail } from '../../slices/tires';
import ProductDetail from '../../components/product_detail';
import { useSearchParams } from 'react-router-dom';
import { getDiscounts } from '../../slices/discounts';
import TireResults from '../../components/tire_results';
import { getAnchor, removeEmpty } from '../../utils';
import { SVGInfo } from '../../components/svg';

export default function WebshopContainer(props) {

    const dispatch = useDispatch()
    const anchor = getAnchor();
    const tireParams = useSelector((state) => state.tires.params || { 'breidd': [], 'profill': [], 'felgustaerd': [], 'framleidandi': [] });
    const tires = useSelector((state) => state.tires.tireList);
    const loadingTires = useSelector((state) => state.tires.loading);
    const selectedTire = useSelector(state => state.tires.selectedTire)

    /* Get URL params, coming from frontpage or copy/paste URL */
    let [searchParams, setSearchParams] = useSearchParams();
    const width = searchParams.get("breidd") || ""
    const aspect_ratio = searchParams.get("profill") || ""
    const diameter = searchParams.get("felgustaerd") || ""
    const producer = searchParams.get("framleidandi") || ""
    const group = searchParams.get("tegund") || ""
    const licence_plate = searchParams.get("bilnumer") || ""

    /* Hooks for all filters on the page */
    const [selectedWidth, setSelectedWidth] = useState(width);
    const [selectedAspectRatio, setSelectedAspectRatio] = useState(aspect_ratio);
    const [selectedDiameter, setSelectedDiameter] = useState(diameter);
    const [selectedProducer, setSelectedProducer] = useState(producer);
    const [selectedGroup, setSelectedGroup] = useState(group);
    const [selectedLicensePlate, setSelectedLicensePlate] = useState(licence_plate);
    const [loadingNewTires, setLoadingNewTires] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const [clearLicencePlate, setClearLicencePlate] = useState(false)

    useEffect(() => {
        if (anchor) {
            dispatch(getTireDetail({ tireId: anchor }))
        }
    }, [])

    function buildQueryParams() {
        let query_params = "?";
        if (selectedLicensePlate !== "") {
            query_params += `l=${selectedLicensePlate}&`;
        } else {
            if (selectedWidth !== "") {
                query_params += `w=${selectedWidth}&`;
            }
            if (selectedAspectRatio !== "") {
                query_params += `a=${selectedAspectRatio}&`;
            }
            if (selectedDiameter !== "") {
                query_params += `d=${selectedDiameter}&`;
            }
            if (selectedProducer !== "") {
                query_params += `p=${selectedProducer}&`;
            }
        }
        if (selectedGroup !== "") {
            query_params += `g=${selectedGroup}&`;
        }

        // Remove trailing '&' if it exists
        if (query_params.endsWith("&")) {
            query_params = query_params.slice(0, -1);
        }
        return query_params;
    }

    /* Fetch all tires on init based on initial params */
    useEffect(() => {
        dispatch(getDiscounts())
        setLoadingNewTires(true)
        Promise.resolve(dispatch(getTireParams()))
            .then(() => {
                Promise.resolve(dispatch(getTires(buildQueryParams())))
                    .then(() => {
                        setLoadingNewTires(false)
                    })
            })
    }, [])

    useEffect(() => {
        setLoadingNewTires(true)
        Promise.resolve(dispatch(getTires(buildQueryParams())))
            .then(() => {
                setLoadingNewTires(false)
            })
        let params = {
            breidd: selectedWidth,
            profill: selectedAspectRatio,
            felgustaerd: selectedDiameter,
            framleidandi: selectedProducer,
            bilnumer: selectedLicensePlate,
            tegund: selectedGroup,
        }
        setSearchParams(removeEmpty(params))
    }, [selectedGroup, selectedProducer])

    /* If you search by filters, clear licence plate and fetch tires */
    useEffect(() => {
        if (selectedWidth !== "" || selectedAspectRatio !== "" || selectedDiameter !== "" || selectedProducer !== "") {
            setSelectedLicensePlate("")
            setIsDirty(true)
        } else if (selectedWidth === "" && selectedAspectRatio === "" && selectedDiameter === "" && selectedLicensePlate === "") {
            setIsDirty(true)
        }
        let params = {
            breidd: selectedWidth,
            profill: selectedAspectRatio,
            felgustaerd: selectedDiameter,
            framleidandi: selectedProducer,
            bilnumer: selectedLicensePlate,
            tegund: selectedGroup,
        }
        setSearchParams(removeEmpty(params))

    }, [selectedWidth, selectedAspectRatio, selectedDiameter])

    /* If you search by licence plate, clear filters and fetch tires */
    useEffect(() => {
        if (selectedLicensePlate !== "") {
            setSelectedWidth("")
            setSelectedAspectRatio("")
            setSelectedDiameter("")
            setIsDirty(true)
   
        } else if (selectedWidth === "" && selectedAspectRatio === "" && selectedDiameter === "" && selectedLicensePlate === "") {
            setIsDirty(true)
        }
        let params = {
            breidd: selectedWidth,
            profill: selectedAspectRatio,
            felgustaerd: selectedDiameter,
            framleidandi: selectedProducer,
            bilnumer: selectedLicensePlate,
            tegund: selectedGroup,
        }
        setSearchParams(removeEmpty(params))
    }, [selectedLicensePlate])

    useEffect(() => {

        if (isDirty) {
            setLoadingNewTires(true)
            Promise.resolve(dispatch(getTires(buildQueryParams())))
                .then(() => {
                    setLoadingNewTires(false)
                    setIsDirty(false)
                })
        }

    }, [isDirty])

    return (
        <>
            <div className={`row justify-content-center shop-search-container header`}>
                <div className='box p-0'><div className='rectangle'></div></div>
                <div className='col-16 outer-col'>
                    <div className='container-fluid clamped'>
                        <div className='row justify-content-center'>
                            <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-7 col-16">
                                <h2 className='d-lg-block font-white mb-0'>Finndu réttu dekkin</h2>
                                <h3 className='subtitle font-italic d-block font-white'>... fyrir þinn bíl</h3>
                            </div>
                            <div className="col-xxl-4 col-lg-7 col-md-9 col-16 mt-4 mt-md-0">
                                <SearchLicensePlate key={`licence-${selectedLicensePlate}`} label="Bílnúmer:" value={selectedLicensePlate} callback={setSelectedLicensePlate} hideClear={true} />
                            </div>
                            <div className="col-lg-4 col-md-8 col-16 offset-md-6 offset-lg-0 mt-4 mt-lg-0 d-flex align-items-end">
                                <p className='mb-0 comment'>Athugað að leitað er eftir dekkjastærðum skv Bifreiðaskrá. Það er ekki í öllum tilfellum sama stærð og er undir bílnum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(typeof tires === 'object') && Object.keys(tires).includes('license_plate') &&
                <div className='row justify-content-center license-plate-results clamped mx-auto'>
                    <div className="col-xxl-8 col-xl-7 col-lg-9">
                        <h4 className="mb-4"><span>Niðurstöður fyrir </span>{tires['license_plate'].toUpperCase()} {tires['make']} {tires['model']}{tires['color'] !== '' && `, ${tires['color']}`}</h4>
                    </div>
                    <div className="col-xxl-4 col-xl-7 col-lg-7">
                        {tires['remark'] !== '' &&
                            <div className="remark alert alert-warning d-flex" role="alert">
                                <SVGInfo width={55} height={55} color="#f89d1e" />
                                <div>
                                    <div className="remark-title d-flex flex-wrap"><span className='me-2'>Athugasemd</span> <div className="remark-info" dangerouslySetInnerHTML={{ __html: tires['remark'] }}></div></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            <div className='row justify-content-center shop-search-container clamped mx-auto'>
                <div className='col-xxl-12 col-xl-14 col-16 order-2 order-lg-1'>
                    <div className='row flex-wrap'>
                        <div className="col select-container d-flex align-items-end">
                            <SearchSelect label="Breidd:" value={selectedWidth} options={tireParams.width} callback={setSelectedWidth} />
                        </div>
                        <div className="col select-container d-flex align-items-end">
                            <SearchSelect label="Prófíll:" value={selectedAspectRatio} options={tireParams.aspect_ratio} callback={setSelectedAspectRatio} />
                        </div>
                        <div className="col select-container d-flex align-items-end">
                            <SearchSelect label="Felgustærð:" value={selectedDiameter} options={tireParams.diameter} callback={setSelectedDiameter} />
                        </div>
                        <div className="col select-container d-flex align-items-end">
                            <SearchSelect label="Framleiðandi:" value={selectedProducer} options={tireParams.producer} callback={setSelectedProducer} />
                        </div>
                        <div className="col select-container d-flex align-items-end">
                            <SearchSelect hideClear={true} value={selectedGroup} options={[["", "Öll dekk"]].concat(tireParams.group)} callback={setSelectedGroup} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`row justify-content-center clamped mx-auto tire-results ${loadingNewTires ? "loading" : ""}`}>
                <div className='col-xxl-12 col-xl-14 col-16'>
                    <div className='row justify-content-center justify-content-md-start'>
                        {(Array.isArray(tires) && tires.length > 0 || Object.keys(tires).includes('license_plate')) ?
                            <TireResults tires={tires} />
                            :
                            loadingTires ?
                                <div className="spinner-border spinner" role="status">
                                    <span className="visually-hidden">Sæki dekk...</span>
                                </div>
                                :
                                <><div className='col-12'>Engin dekk fundust</div></>
                        }
                    </div>
                </div>
            </div>
            {selectedTire && (
                <ProductDetail selectedTire={selectedTire} productList={tires} />
            )}
        </>
    );
}