import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTire } from '../../slices/tires';
import { SVGCloseModal, SVGArrowHead, SVGArrowBack, SVGWinter, SVGSummer, SVGAllYear } from '../svg';
import ProductCartAdder from '../product_cart_adder';
import { setAnchor, removeAnchor } from '../../utils';

function ProductDetail({ selectedTire, productList }) {
    const dispatch = useDispatch();
    const imgRef = useRef(null);
    const [currentLargeImage, setCurrentLargeImage] = useState(selectedTire.card_image);
    const [activeImageIndex, setActiveImageIndex] = useState(null);

    useEffect(() => {
        setAnchor(`${selectedTire.product_id}`)
    }, [selectedTire])

    const setPreviousProduct = () => {
        const index = productList.findIndex(product => product.product_id === selectedTire.product_id);
        if (index > 0) {
            dispatch(setSelectedTire(productList[index - 1]));
        } else {
            dispatch(setSelectedTire(productList[productList.length - 1]));
        }
    }

    const clearSelectedTire = () => {
        dispatch(setSelectedTire(null))
        removeAnchor()
    }

    const setNextProduct = () => {
        const index = productList.findIndex(product => product.product_id === selectedTire.product_id);
        if (index < productList.length - 1) {
            dispatch(setSelectedTire(productList[index + 1]));
        } else {
            dispatch(setSelectedTire(productList[0]));
        }
    }

    const changeLargeImage = (newImage, index) => {
        setCurrentLargeImage(newImage);
        setActiveImageIndex(index);
    }

    useEffect(() => {
        changeLargeImage(selectedTire.card_image)
        setActiveImageIndex(null);

    }, [selectedTire]);

    const handleMouseOver = (e) => {
        const scale = e.currentTarget.getAttribute("data-scale");
        if (imgRef.current) {
            imgRef.current.style.transform = `scale(${scale})`;
        }
    };

    const handleMouseOut = () => {
        if (imgRef.current) {
            imgRef.current.style.transform = "scale(1)";
            imgRef.current.style.backgroundImage = imgRef.current.style.backgroundImage.replace('width-1920', 'width-584');
        }
    };

    const getScroll = function () {
        if (window.pageYOffset != undefined) {
            return [pageXOffset, pageYOffset];
        } else {
            var sx, sy, d = document,
                r = d.documentElement,
                b = d.body;
            sx = r.scrollLeft || b.scrollLeft || 0;
            sy = r.scrollTop || b.scrollTop || 0;
            return [sx, sy];
        }
    }

    const handleMouseMove = (e) => {
        const [scrollX, scrollY] = getScroll();
        if (imgRef.current) {
            const position = e.currentTarget.getBoundingClientRect()
            const offsetX = e.pageX - position.x - scrollX;
            const offsetY = e.pageY - position.y - scrollY;
            const originX = (offsetX / e.currentTarget.offsetWidth) * 100;
            const originY = (offsetY / e.currentTarget.offsetHeight) * 100;
            imgRef.current.style.transformOrigin = `${originX}% ${originY}%`;
            imgRef.current.style.backgroundImage = imgRef.current.style.backgroundImage.replace('width-584', 'width-1920');
        }
    };

    return (
        <div className="container-fluid position-fixed product-detail">
            <div className="row justify-content-center mx-auto clamped">
                <div className="col-xl-12 col-lg-14 col-16 position-relative">
                    <div className="previous-product" onClick={() => setPreviousProduct()}><SVGArrowHead /></div>
                    <div className='bg-card d-flex position-relative'>
                        <div className='close position-absolute' onClick={() => clearSelectedTire()} >
                            <div className='d-none d-lg-block'><SVGCloseModal /></div>
                            <div className='d-block d-lg-none'><SVGArrowBack /><span>Til baka</span></div>
                            <div className='tire-type'>{selectedTire.group && selectedTire.group.title === 'Vetrardekk' && <SVGWinter />}</div>
                            <div className='tire-type'>{selectedTire.group && selectedTire.group.title === 'Sumardekk' && <SVGSummer />}</div>
                            <div className='tire-type'>{selectedTire.group && selectedTire.group.title === 'Heilsársdekk' && <SVGAllYear />}</div>
                        </div>
                        <div className='product-detail-images-container d-flex flex-wrap'>
                            <div className="product-detail-extra-images d-flex flex-row flex-lg-column order-lg-1 order-2">
                                <div className={`img-container ${activeImageIndex === null ? 'active' : ''}`} onClick={() => changeLargeImage(selectedTire.card_image, null)}>
                                    <img src={selectedTire.card_image} className='d-flex mx-auto' alt="Main Thumbnail" />
                                </div>
                                {selectedTire.extra_images.map((imageSrc, index) => {
                                    return (
                                        <div className={`img-container ${activeImageIndex === index ? 'active' : ''}`} key={index} onClick={() => changeLargeImage(imageSrc, index)}>
                                            <img src={imageSrc} className='d-flex mx-auto' alt="Extra Thumbnail" />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="product-detail-image d-flex d-md-block d-lg-flex flex-column order-lg-2 zoom-container">
                                <h1 className='d-block d-lg-none'>{selectedTire.title}</h1>
                                <h3 className='d-block d-lg-none'>{selectedTire.width}/{selectedTire.aspect_ratio}R{selectedTire.diameter}</h3>
                                {selectedTire.description2 && <h5 className='d-block d-lg-none'>{selectedTire.description2}</h5>}
                                <div className="position-relative">
                                    <div
                                        className="product-img-container"
                                        data-scale="4"
                                        onMouseOver={handleMouseOver}
                                        onMouseOut={handleMouseOut}
                                        onMouseMove={handleMouseMove}
                                    >
                                        <div className='product-img' ref={imgRef} style={{ backgroundImage: `url(${currentLargeImage})` }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-detail-text d-flex flex-column col">
                            <h2 className='order-lg-1 d-none d-lg-block'>{selectedTire.title}</h2>
                            <h3 className='order-lg-2 d-none d-lg-block'>{selectedTire.width}/{selectedTire.aspect_ratio}R{selectedTire.diameter}</h3>
                            {selectedTire.description2 && <h5 className='order-lg-3 d-none d-lg-block'>{selectedTire.description2}</h5>}
                            <p className="small order-3 m-0 p-0">{selectedTire.product_number}</p>
                            {selectedTire.eprel && <p className="small order-3 m-0 p-0"><a class="link" href={`https://eprel.ec.europa.eu/screen/product/tyres/${selectedTire.eprel}`} target="_blank">Vöruupplýsingablað</a></p>}
                            <div className="order-lg-4 order-2 product-description mb-5">{selectedTire.description}</div>
                            <div className='order-lg-5 order-1 product-cart-adder-container mt-auto'>
                                <ProductCartAdder product={selectedTire} />
                            </div>
                        </div>
                    </div>
                    <div className="next-product" onClick={() => setNextProduct()}><SVGArrowHead /></div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetail;

