import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TireList from '../tire_list';

function TireResults({ tires }) {

    if (Array.isArray(tires)) {
        return (<TireList tires={tires} />)
    } else {
        if (typeof tires === 'object') {
            return (
                <>
                    {Object.keys(tires.wheel_params).length == 2 ?
                        <>
                            {tires.wheel_params[Object.keys(tires.wheel_params)[0]]["tyre"] === tires.wheel_params[Object.keys(tires.wheel_params)[1]]["tyre"] ? (
                                <>
                                    <div className="col-16">
                                        <div className='d-flex justify-content-between align-items-center no-tab-title'>
                                            <div className='title'>Stærð <span className=''>{tires.wheel_params[Object.keys(tires.wheel_params)[0]]["tyre"].split(" ")[0]}</span></div>
                                        </div>
                                    </div>
                                    <div className='container-fluid clamped'>
                                        <div className="row">
                                            {tires.wheel_params[Object.keys(tires.wheel_params)[0]]["tires"].length === 0 ?
                                                <div className="no-tires mt-5">Engin dekk fundust fyrir þessa stærð.</div>
                                                :
                                                <TireList tires={tires.wheel_params[Object.keys(tires.wheel_params)[0]]["tires"]} />
                                            }
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <Tabs className="tabs-container">
                                    <TabList>
                                        <Tab>Framöxull <span>{tires.wheel_params[Object.keys(tires.wheel_params)[0]]["tyre"].split(" ")[0]}</span></Tab>
                                        <Tab>Afturöxull<span>{tires.wheel_params[Object.keys(tires.wheel_params)[1]]["tyre"].split(" ")[0]}</span> </Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className='container-fluid clamped'>
                                            <div className="row">
                                                {tires.wheel_params[Object.keys(tires.wheel_params)[0]]["tires"].length === 0 ?
                                                    <div className="no-tires mt-5">Engin dekk fundust fyrir þessa stærð.</div>
                                                    :
                                                    <TireList tires={tires.wheel_params[Object.keys(tires.wheel_params)[0]]["tires"]} />
                                                }
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className='container-fluid clamped'>
                                            <div className="row">
                                                {tires.wheel_params[Object.keys(tires.wheel_params)[1]]["tires"].length === 0 ?
                                                    <div className="no-tires mt-5">Engin dekk fundust fyrir þessa stærð.</div>
                                                    :
                                                    <TireList tires={tires.wheel_params[Object.keys(tires.wheel_params)[1]]["tires"]} />
                                                }
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            )}
                        </>
                        : (
                            <Tabs className="tabs-container">
                                <TabList>
                                    {Object.keys(tires.wheel_params).map((key) => {
                                        return <Tab>{key.replace("Axle", "Öxull ")} <span>{tires.wheel_params[key]["tyre"].split(" ")[0]}</span></Tab>
                                    })}
                                </TabList>
                                {Object.keys(tires.wheel_params).map((key) => {
                                    return (<TabPanel>
                                        <div className='container-fluid clamped'>
                                            <div className="row">
                                                {tires.wheel_params[key]["tires"].length === 0 ?
                                                    <div className="no-tires mt-5">Engin dekk fundust fyrir þessa stærð.</div>
                                                    :
                                                    <TireList tires={tires.wheel_params[key]["tires"]} />
                                                }
                                            </div>
                                        </div>
                                    </TabPanel>);
                                })}
                            </Tabs>
                        )
                    }
                </>
            )
        }
    }

}

export default TireResults;
