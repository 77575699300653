import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumber, getAnchor, setAnchor, getInvoiceStatusDescriptive, getOrderStatusDescriptive, invoiceDateFormat } from '../../utils';
import { getInvoices } from '../../slices/invoices';
import { getOrders } from '../../slices/orders';
import { updateUser } from '../../slices/users';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SVGPdf } from '../svg';
import Table, { SelectColumnFilter, TextSearchFilter } from '../table';
import { useForm, Controller } from 'react-hook-form';

function MinarSidur() {
    const dispatch = useDispatch()
    const invoices = useSelector(state => state.invoices.invoices || [])
    const orders = useSelector(state => state.orders.orders || [])
    const user = useSelector(state => state.users.currentUser || {})
    const [updateUserLoading, setUpdateUserLoading] = useState(false)
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        dispatch(getInvoices());
        dispatch(getOrders());
        getDefaultIndex();
        window.addEventListener("hashchange", function () {
            getDefaultIndex()
        });
    }, []);


    const { control, setValue, handleSubmit, setError, formState: { errors } } = useForm({
        defaultValues: {
            fullName: user.first_name + ' ' + user.last_name,
            email: user.email,
            phone: user.phone,
            password: '',
            password2: ''
        }
    });

    useEffect(() => {
        if(user.first_name) {
           setValue("fullName", user.first_name + ' ' + user.last_name);
        }
        if(user.email) {
           setValue("email", user.email);
        }
        if(user.phone) {
           setValue("phone", user.phone);
        }
     }, [user, setValue]);

    const onSubmit = (data) => {
        if ((data.password !== '' || data.password2 !== '') && data.password !== data.password2) {
            setError("password2", {
                type: "manual",
                message: "Lykilorðin eru ekki eins"
            });
            return;
        }

        const [first_name, ...last_name] = data.fullName.split(' ');
        const formattedData = {
            id: user.id,
            first_name,
            last_name: last_name.join(' '),
            email: data.email,
            phone: data.phone,
            password: data.password,
            password2: data.password2
        };
        setUpdateUserLoading(true);
        Promise.resolve(dispatch(updateUser(formattedData))).then(() => {
            setUpdateUserLoading(false);
        })
    };

    function getDefaultIndex() {
        const anchor = getAnchor();

        if (anchor === 'pantanir') {
            setTabIndex(0);
        } else if (anchor === 'reikningar') {
            setTabIndex(1);
        } else if (anchor === 'minar-upplysingar') {
            setTabIndex(2);
        } else {
            setTabIndex(0);
        }
    }

    const pantanir_columns = React.useMemo(
        () => [
            {
                Header: 'Reikningsnúmer',
                accessor: 'number',
                filter: 'includes',
            },
            {
                Header: 'Dags. pöntunar',
                accessor: 'orderDate',
                Cell: ({ value }) => {
                    return invoiceDateFormat(value)
                },
                sortingFn: "datetime"
            },
            {
                Header: 'Upphæð',
                accessor: 'totalAmountIncludingTax',
                Cell: ({ value }) => {
                    return `${formatNumber(value)} kr.`;
                }
            },
            {
                Header: 'Staða',
                accessor: 'status',
                filter: 'includes',
                Cell: ({ value }) => {
                    return <strong>{value}</strong>;
                }

            },
        ])

    const reikningar_columns = React.useMemo(
        () => [
            {
                Header: 'Reikningsnúmer',
                accessor: 'number',
                Filter: TextSearchFilter,
                filter: 'includes',
            },
            {
                Header: 'Útgáfudagur',
                accessor: 'invoiceDate',
                Cell: ({ value }) => {
                    return invoiceDateFormat(value)
                },
                sortingFn: "datetime"
            },
            {
                Header: 'Eindagi',
                accessor: 'dueDate',
                Cell: ({ value }) => {
                    return invoiceDateFormat(value)
                },
                sortingFn: "datetime"
            },
            {
                Header: 'Upphæð',
                accessor: 'totalAmountIncludingTax',
                Cell: ({ value }) => {
                    return `${formatNumber(value)} kr.`;
                }
            },
            {
                Header: '',
                accessor: 'status',
                Filter: SelectColumnFilter,
                filter: 'includes',
                Cell: ({ value }) => {
                    return <strong>{value}</strong>;
                }
            },
            {
                Header: 'PDF',
                accessor: 'id',
                className: "text-center",
                Cell: ({ value }) => {
                    return <a href={`/api/invoices/${value}/pdf`} target="_blank"><SVGPdf /></a>
                }
            },
        ])

    return (
        <div className="minar-sidur-wrapper">

            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList className='border-bottom d-none d-md-flex'>
                    <h1>Mínar síður</h1>
                    <Tab className="list-unstyled" onClick={() => setAnchor('pantanir')}>Pantanir í vinnslu</Tab>
                    <Tab className="list-unstyled" onClick={() => setAnchor('reikningar')}>Reikningar</Tab>
                    <Tab className="list-unstyled" onClick={() => setAnchor('minar-upplysingar')}>Mínar upplýsingar</Tab>
                </TabList>
                <TabPanel>
                    <h1 className='d-block d-md-none'>Pantanir</h1>
                    <Table
                        columns={pantanir_columns}
                        initialSort={[{ id: "orderDate", desc: true }]}
                        data={orders}
                    />
                </TabPanel>
                <TabPanel className="pt-5">
                    <h1 className='d-block d-md-none'>Reikningar</h1>
                    <Table
                        columns={reikningar_columns}
                        data={invoices}
                        initialSort={[{ id: "invoiceDate", desc: true }]}
                        paginate
                    />
                </TabPanel>
                <TabPanel>
                    <div className="container-fluid">
                        <div className="row justify-content-around">
                            <div className="col-12 col-md-6">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="name">Nafn</label>
                                        <Controller
                                            name="fullName"
                                            control={control}
                                            render={({ field }) => <input className="form-control" {...field} />}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="email">Netfang</label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => <input className="form-control" {...field} />}
                                        />
                                    </div>
                                    <div className="form-group mb-5">
                                        <label htmlFor="phone">Símanúmer</label>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            render={({ field }) => <input className="form-control" {...field} />}
                                        />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="password">Lykilorð</label>
                                        <Controller
                                            name="password"
                                            control={control}
                                            render={({ field }) => <input className="form-control" type="password" {...field} />}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="password">Lykilorð aftur</label>
                                        <Controller
                                            name="password2"
                                            control={control}
                                            render={({ field }) => <input className="form-control" type="password" {...field} />}
                                        />
                                        {errors.password2 && <p>{errors.password2.message}</p>}
                                    </div>
                                    <button type="submit" className="btn btn-primary mb-5">
                                        {!updateUserLoading ? "Uppfæra" : <span className="spinner-border spinner-border-sm" role="status"></span>}
                                    </button>
                                </form>

                            </div>
                            <div className="col-12 col-md-6">

                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="name">Nafn fyrirtækis</label>
                                        <input type="text" className="form-control" readOnly placeholder={user.active_company?.title} disabled />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="email">Kennitala</label>
                                        <input type="text" className="form-control" readOnly placeholder={user.active_company?.ssn} disabled />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="address">Heimilisfang</label>
                                        <input type="text" className="form-control" readOnly placeholder={user.active_company?.address1} disabled />
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-4 mb-3">
                                            <label htmlFor="zip">Póstnumer</label>
                                            <input type="text" className="form-control" readOnly placeholder={user.active_company?.postal_code} disabled />
                                        </div>
                                        <div className="form-group col-12 mb-3">
                                            <label htmlFor="city">Staður</label>
                                            <input type="text" className="form-control" readOnly placeholder={user.active_company?.city} disabled />
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                </TabPanel>
            </Tabs>
        </div>

    );
}

export default MinarSidur;