import React, { useState, useEffect, useRef } from 'react';
import { SVGCart, SVGInvoice, SVGOrders, SVGRecycleBin, SVGUser, SVGUserInfo } from '../svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCart, removeCartLine } from '../../slices/cart';
import { formatNumber } from '../../utils';
import { useClickAway } from "@uidotdev/usehooks";

function Cart() {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart.cart || [])
    // const adding = useSelector(state => state.cart.adding || false)
    const [cartOpen, setCartOpen] = useState(false)
    // const [fixCart, setFixCart] = useState(false)
    // const fixCartRef = useRef(fixCart);
    const [loadingDelete, setLoadingDelete] = useState(null);

    const toggleCart = () => {
        setCartOpen(!cartOpen)
    }

    const ref = useClickAway(() => {
        setCartOpen(false);
    });

    const removeFromCart = (id) => {
        setLoadingDelete(id)
        Promise.resolve(dispatch(removeCartLine({ "line_id": id })))
            .then(() => {
                setLoadingDelete(null)
            })
    }

    useEffect(() => {
        dispatch(getCart());
    }, []);

    // // Update the ref whenever fixCart changes
    // useEffect(() => {
    //     fixCartRef.current = fixCart;
    // }, [fixCart]); // This will run whenever fixCart changes, keeping the ref up-to-date

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.scrollY > 120 && fixCartRef.current == false) {
    //             setFixCart(true);
    //         }
    //         if (window.scrollY < 120 && fixCartRef.current == true) {
    //             setFixCart(false);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     // Cleanup the event listener on component unmount
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };

    // }, []);

    // useEffect(() => {
    //     if (adding) {
    //         setCartOpen(true)
    //         setTimeout(() => setCartOpen(false), 3000)
    //     }
    // }, [adding]);

    return (
        <div>
            <div>
                <div className='d-block d-xl-none position-relative'>
                    <a href='/karfa'><SVGCart /></a>
                    <span className={`cart-count ${cart.length > 0 && 'visible'}`}>{cart.length}</span>
                </div>
            </div>
            <div className={`cart-wrapper d-none d-xl-block position-relative ${cartOpen ? 'cart-open' : ''}`}>
                <div className="cart-button d-flex align-items-center" onClick={toggleCart}>
                    <SVGCart />
                    <div className="d-none d-xl-block cart-text">Karfa</div>
                    <span className={`cart-count ${cart.length > 0 && 'visible'}`}>{cart.length}</span>
                </div>
                <div ref={ref} className={`cart dropdown-menu bg-white ${cartOpen ? 'd-block' : 'd-none'}`}>
                    {cart.length == 0 ? (
                        <div className="cart-empty m-3 text-center">
                            Karfan er tóm
                        </div>
                    ) : (
                        <>
                            <ul className="cart-item-list p-0">
                                {cart.map((item, index) => {
                                    return (
                                        <li key={index} className="cart-item d-flex">
                                            <div className="cart-item-image">
                                                <img src={item.product.image} />
                                            </div>
                                            <span>{item.quantity} stk</span>
                                            <div className="cart-item-text">
                                                {item.product.title}
                                                <div className="price">{formatNumber(item.netAmountIncludingTax)} kr.</div>
                                            </div>
                                            <div className="cart-item-remove">
                                                <button onClick={() => removeFromCart(item.id)}>
                                                    {loadingDelete === item.id ?
                                                        <div className="spinner-border small" role="status" />
                                                        :
                                                        <SVGRecycleBin />
                                                    }
                                                </button>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="cart-footer">
                                <div className="row total">
                                    <div className="col">Samtals</div>
                                    <div className="col text-end">{formatNumber(cart.reduce(function (a, b) { return a + b.netAmountIncludingTax }, 0))} kr.</div>
                                </div>
                                <div className="row buttons">
                                    <div className="col"><button className="btn btn-primary w-100" onClick={() => window.location = window.PAGES.webShopCartPageUrl}>Panta</button></div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Cart;