import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../slices/cart';
import { formatNumber } from '../../utils';
import { SVGMinus, SVGPlus } from '../svg';

function ProductCartQuantity({ quantity, setQuantity, inventory }) {

    return (
        <>
            <div className="svg-container" onClick={() => quantity > 1 && setQuantity(quantity - 1)}>
                <SVGMinus />
            </div>
            <input type="text" value={quantity} onChange={(e) => e.target.value === "" ? setQuantity(0) : setQuantity(parseInt(e.target.value))} />
            <div  className="svg-container" onClick={() => (quantity+1 <= inventory) && setQuantity(quantity + 1)}>
                <SVGPlus />
            </div>
        </>
    )
}

export default ProductCartQuantity;
