import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from "../../slices/modal";
import { SVGCloseModal } from '../svg';

const Modal = ({ id, title, ...props }) => {

    const activeModal = useSelector(state => state.modals.activeModal);
    const dispatch = useDispatch()

    return (
        <>
            {activeModal === id
                ? ReactDOM.createPortal(
                    <>
                        <div className="mitra-modal--overlay" onClick={() => dispatch(closeModal())}></div>
                        <div className="mitra-modal--wrapper">
                            <div className="mitra-modal">
                                <div className="mitra-modal--header">
                                    <h2>{title}</h2>
                                    <button
                                        type="button"
                                        className="mitra-modal--close-button"
                                        onClick={() => dispatch(closeModal())}
                                    >
                                        <SVGCloseModal />
                                    </button>
                                </div>
                                <div className="mitra-modal--body">{props.children}</div>
                            </div>
                        </div>
                    </>,
                    document.body
                )
                : null
            }
        </>
    )
}

Modal.propTypes = {
    isShowing: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};

export default Modal;
