import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../slices/cart';
import { formatNumber } from '../../utils';
import { SVGMinus, SVGPlus } from '../svg';
import ProductCartQuantity from '../product_cart_quantity';
import { setActiveModal } from '../../slices/modal';
import { MODAL_NOT_LOGGED_IN } from '../../const';

function ProductCartAdder({ product }) {

    const dispatch = useDispatch()
    const [quantity, setQuantity] = useState(1);
    const currentUser = useSelector(state => state.users.currentUser);
    const discount = useSelector(state => state.discounts.discounts.find(discount => discount.code === product.discount_group))

    const [loadingAddToCart, setLoadingAddToCart] = useState(null);

    const addProductToCart = (product_id) => {
        if (quantity > 0){
            setLoadingAddToCart(product_id);
            Promise.resolve(dispatch(addToCart({ "item_id": product_id, "quantity": quantity })).then((response) => {
                setQuantity(1);
            })).then(() => {
                setLoadingAddToCart("added");
                setTimeout(() => {
                    setLoadingAddToCart(null);
                }, 3000);
            })
        }
    }

    return (
        <>
            <div className='d-flex align-items-center justify-content-between price-container mt-auto'>
                <div>
                    {product.inventory > 0 &&
                        <div className='quantity-counter d-flex align-items-center justify-content-between'>
                            <ProductCartQuantity quantity={quantity} setQuantity={setQuantity} inventory={product.inventory} />
                        </div>
                    }
                </div>
                <div className="position-relative mt-2">
                    {discount ?
                        <>
                            <span className='old-price'>{formatNumber(product.price * quantity)} kr.</span>
                            <h5 className="card-text text-nowrap"> {formatNumber(product.price * quantity * ((100 - discount.discount) / 100))} kr.</h5>
                        </>
                        :
                        <h5 className="card-text text-nowrap">{formatNumber(product.price * quantity)} kr.</h5>
                    }
                </div>
            </div>
            {product.inventory > 0 ?
                currentUser && currentUser.authenticated ?
                <button onClick={() => addProductToCart(product.product_id)} className={`card-link btn d-flex align-items-center justify-content-center w-100 ${loadingAddToCart !== null && "yellow"}`}>{loadingAddToCart === product.product_id ? <span className="spinner-border small" role="status" /> : loadingAddToCart === "added" ? <span className="fadeout">Vara sett í körfu</span> : "Setja í körfu"}</button>
                :
                <button onClick={() => dispatch(setActiveModal(MODAL_NOT_LOGGED_IN))} className="card-link btn d-flex align-items-center justify-content-center w-100">Setja í körfu</button>
                :
                <button className="card-link btn d-flex align-items-center justify-content-center w-100" disabled>Uppselt</button>
            }
        </>
    )
}

export default ProductCartAdder;
