import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import InvoicesService from "../../api/invoices";
import moment from 'moment';
import { getInvoiceStatusDescriptive } from "../../utils";


export const getInvoices = createAsyncThunk(
    "invoices/get",
    async (args, thunkAPI) => {
        try {
            const response = await InvoicesService.getInvoices(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    invoices: null,
};

const invoicesSlice = createSlice({
    name: "invoices",
    initialState,
    extraReducers: {
        [getInvoices.pending]: (state, action) => {
            state.loading = true;
        },
        [getInvoices.fulfilled]: (state, action) => {
            state.invoices = action.payload.map((invoice) => {
                return {
                    ...invoice,
                    invoiceDate: moment(invoice.invoiceDate).toDate(),
                    dueDate: moment(invoice.dueDate).toDate(),
                    status: getInvoiceStatusDescriptive(invoice.status),
                }
            });
            state.loading = false;
        },
        [getInvoices.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja reikninga";
            state.loading = false;
        },
    },
});

const { reducer } = invoicesSlice;
export default reducer;