import React, { useEffect } from 'react'

import { SVGPaginateNext, SVGPaginatePrev } from '../svg';

import PropTypes from 'prop-types'

const range = (min, max) => Array.from({ length: max - min + 1 }, (_, i) => min + i);
const getPages = (numPages, ellipsis, number = 1, onEachSide = 2, onEnds = 1) => {

    let pages = null

    if (numPages <= (onEachSide, onEnds) * 2) {
        return range(1, numPages)
    }

    if (number > (1 + onEachSide + onEachSide) + 1) {
        pages = range(1, onEnds).concat(ellipsis).concat(range(number - onEachSide, number))
    } else {
        pages = range(1, number)
    }
    if (number < (numPages - onEachSide - onEnds) - 1) {
        pages = pages.concat(range(number + 1, number + onEachSide)).concat(ellipsis).concat(range(numPages - onEnds + 1, numPages))
    } else {
        pages = pages.concat(range(number + 1, numPages))
    }

    return pages
}

const Paginator = ({
    numResults,
    pageIndex,
    pageSize,
    setPageSize,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    numPages,
    ellipsis,
    showPageSizeSelector,
    pageSizeOptions,
}) => {

    let pages = getPages(numPages, ellipsis, pageIndex + 1)

    return (
        <>
            {numResults > 0 &&
                <>
                    <div className="mt-5 text-center pagination-filter">
                        <span>{numResults} færslur - </span>
                        {showPageSizeSelector &&
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}>
                                {pageSizeOptions.map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} á síðu
                                    </option>
                                ))}
                            </select>
                        }
                    </div>
                    <div className="pagination-wrapper mb-5 mt-4 d-flex justify-content-center">
                        <nav aria-label="...">
                            <ul className="pagination">
                                <li className={`page-item d-flex justify-content-center align-items-center ms-0 wider prev ${canPreviousPage ? "" : "disabled"}`}>
                                    {canPreviousPage ?
                                        <a href="javascript:void(0)" onClick={() => previousPage()} className="page-link text font-small ps-0 text-nowrap"><SVGPaginatePrev /></a>
                                        :
                                        <span className="page-link font-small ps-0 text-nowrap"><SVGPaginatePrev /></span>
                                    }
                                </li>
                                {pages.map(p =>
                                    <li className={`page-item d-flex justify-content-center align-items-center ${p === ellipsis ? "disabled" : ""} ${pageIndex + 1 === p ? "active" : ""}`}>
                                        {p === ellipsis ?
                                            <a className="page-link font-small" href="javascript:void(0)" >{p}</a>
                                            :
                                            <a className="page-link font-small" href="javascript:void(0)" onClick={() => gotoPage(p - 1)}>
                                                {p}
                                            </a>
                                        }
                                    </li>
                                )}
                                <li className={`page-item d-flex justify-content-center align-items-center me-0 wider next ${canNextPage ? "" : "disabled"}`}>
                                    {canNextPage ?
                                        <a href="javascript:void(0)" onClick={() => nextPage()} className="page-link text font-small pe-0 text-nowrap"><SVGPaginateNext /></a>
                                        :
                                        <span className="page-link font-small pe-0 text-nowrap"><SVGPaginateNext /></span>
                                    }
                                </li>
                            </ul>
                        </nav>
                    </div>
                </>
            }
        </>
    )
}

Paginator.propTypes = {
    numResults: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    gotoPage: PropTypes.func,
    previousPage: PropTypes.func,
    nextPage: PropTypes.func,
    setPageSize: PropTypes.func,
    canPreviousPage: PropTypes.bool,
    canNextPage: PropTypes.bool,
    numPages: PropTypes.number,
    ellipsis: PropTypes.string,
    showPageSizeSelector: PropTypes.bool,
    pageSizeOptions: PropTypes.array,
}

Paginator.defaultProps = {
    numResults: 0,
    pageIndex: 0,
    pageSize: 10,
    gotoPage: () => { },
    previousPage: () => { },
    nextPage: () => { },
    setPageSize: () => { },
    canPreviousPage: false,
    canNextPage: false,
    numPages: 1,
    ellipsis: '…',
    showPageSizeSelector: true,
    pageSizeOptions: [10, 25, 50, 100]
}

export default Paginator