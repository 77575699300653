import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SVGCart, SVGInvoice, SVGOrders, SVGUser, SVGUserInfo } from '../svg';
import Cart from '../cart'
import Login from '../login'
import { useClickAway } from '@uidotdev/usehooks'

function Userbar() {

    const [minarSidurDropdownOpen, setMinarSidurDropdownOpen] = useState(false)
    const toggleMinarSidurDropdown = () => {
        setMinarSidurDropdownOpen(!minarSidurDropdownOpen)
        // if(minarSidurDropdownOpen == true){
        //     document.body.classList.add('position-fixed', 'w-100');
        // } else {
        //     document.body.classList.remove('position-fixed', 'w-100');
        // }
    }
    const cart = useSelector(state => state.cart.cart || [])
    const currentUser = useSelector(state => state.users.currentUser);
    const selectedTire = useSelector(state => state.tires.selectedTire)

    const ref = useClickAway(() => {
        setMinarSidurDropdownOpen(false);
    });

    const [didScroll, setDidScroll] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    const delta = 10;

    useEffect(() => {
        if (window.innerWidth < 1024) {
            if (selectedTire != null ) {
                document.body.classList.add('header-down');
                document.body.classList.remove('header-up');
            } else {
                document.body.classList.remove('header-down');
            }
        }
    }, [selectedTire])

    useEffect(() => {
        const handleScroll = () => {
            setDidScroll(true);
        };
        
        window.addEventListener('scroll', handleScroll);

        const interval = setInterval(() => {
            if (didScroll) {
                hasScrolled();
                setDidScroll(false);
            }
        }, 140);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(interval);
        };
    }, [didScroll]);

    function hasScrolled() {
        const st = window.scrollY;

        if (Math.abs(lastScrollTop - st) <= delta) return;

        if (st < 120) {
            document.body.classList.remove('header-down', 'header-up');
        } else if (st > lastScrollTop) {
            document.body.classList.remove('header-down');
            document.body.classList.add('header-up');
        } else if (st > 120) {
            document.body.classList.remove('header-up');
            document.body.classList.add('header-down');
        }
        setLastScrollTop(st);
    }

    return (
        <>
            {currentUser &&
                <>
                    {currentUser.authenticated ? (
                        <ul className="navbar-nav right-nav d-flex flex-row">
                            <li className={`nav-item d-flex me-0 p-0 align-items-center my-pages-container ${minarSidurDropdownOpen && 'dropdown-open'} ${cart.length > 0 && 'cart-has-items'}`}>
                                <div className="login-menu position-relative">
                                    <div className="dropdown">
                                        <div className="nav-link login d-flex align-items-center" onClick={toggleMinarSidurDropdown}>
                                            <SVGUser />
                                            <div className="d-none d-xl-block">Mínar síður</div>
                                        </div>
                                        <ul ref={ref} className={`dropdown-menu ${minarSidurDropdownOpen ? 'd-block' : 'd-none'}`}>
                                            <li className='user'>
                                                <div className='d-flex d-lg-none'>
                                                    <div className='user-icon d-flex align-items-center justify-content-center'>
                                                        <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
                                                            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g id="menu-minar---mobile" transform="translate(-40, -109)" fill="#F89D1E" fillRule="nonzero">
                                                                    <path d="M51,109 C54.3137085,109 57,111.686292 57,115 C57,117.318952 55.6844484,119.33064 53.7589507,120.329459 C58.4988437,121.480619 62,125.531962 62,130.352941 C62,130.710302 61.6921958,131 61.3125,131 C60.9328042,131 60.625,130.710302 60.625,130.352941 C60.625,125.349891 56.3157407,121.294118 51,121.294118 C45.6842593,121.294118 41.375,125.349891 41.375,130.352941 C41.375,130.710302 41.0671958,131 40.6875,131 C40.3078042,131 40,130.710302 40,130.352941 C40,125.531962 43.5011563,121.480619 48.2409266,120.328365 C46.3155516,119.33064 45,117.318952 45,115 C45,111.686292 47.6862915,109 51,109 Z M51,110.333333 C48.4226712,110.333333 46.3333333,112.422671 46.3333333,115 C46.3333333,117.577329 48.4226712,119.666667 51,119.666667 C53.5773288,119.666667 55.6666667,117.577329 55.6666667,115 C55.6666667,112.422671 53.5773288,110.333333 51,110.333333 Z" id="Combined-Shape-Copy-3"></path>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h5>{currentUser.first_name} {currentUser.last_name}</h5>
                                                        <div className='company'>{currentUser.active_company?.title}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li><a className="dropdown-item d-flex align-items-center" onClick={() => { window.location.href = `${window.PAGES.minarSidurPageUrl}#pantanir`; }}><SVGOrders /> Pantanir</a></li>
                                            <li><a className="dropdown-item d-flex align-items-center" onClick={() => { window.location.href = `${window.PAGES.minarSidurPageUrl}#reikningar`; }}><SVGInvoice /> Reikningar</a></li>
                                            <li><a className="dropdown-item d-flex align-items-center border-0" onClick={() => { window.location.href = `${window.PAGES.minarSidurPageUrl}#minar-upplysingar`; }}><SVGUserInfo /> Mínar upplýsingar</a></li>
                                            <li><button className="btn w-100 mt-4 mb-2" onClick={() => window.location.href = "/logout"}>Útskrá</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item d-flex me-0 p-0 align-items-center cart-container">
                                <Cart />
                            </li>
                        </ul>
                    ) : (
                        <ul className="navbar-nav right-nav d-flex flex-row">
                            <li className={`nav-item d-flex me-0 p-0 align-items-center my-pages-container not-logged-in`}>
                                <div className="login-menu position-relative">
                                    <div className="dropdown">
                                        <div className="nav-link login d-flex align-items-center" onClick={() => window.location.href = "/login"}>
                                            <SVGUser />
                                            <div className="d-none d-xl-block">Mínar síður</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    )}
                </>
            }
        </>
    );
}

export default Userbar;