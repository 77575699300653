import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DiscountsService from "../../api/discounts";
import { getDiscountStatusDescriptive } from "../../utils";
import moment from 'moment';


export const getDiscounts = createAsyncThunk(
    "discounts/get",
    async (args, thunkAPI) => {
        try {
            const response = await DiscountsService.getDiscounts(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    discounts: [],
};

const discountsSlice = createSlice({
    name: "discounts",
    initialState,
    extraReducers: {
        [getDiscounts.pending]: (state, action) => {
            state.loading = true;
        },
        [getDiscounts.fulfilled]: (state, action) => {
            state.discounts = action.payload
            state.loading = false;
        },
        [getDiscounts.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja afslætti";
            state.loading = false;
        },
    },
});

const { reducer } = discountsSlice;
export default reducer;