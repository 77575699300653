import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../../api/users";


export const getCurrentUser = createAsyncThunk(
  "currentUser/get",
  async (args, thunkAPI) => {
    try {
      const response = await UserService.getCurrentUser(args);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "currentUser/update",
  async (args, thunkAPI) => {
    try {
      const response = await UserService.updateUser(args);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  currentUser: null,
  loading: false,
  loadingDetail: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [getCurrentUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload;
      state.loading = false;
    },
    [getCurrentUser.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja dekk";
      state.loading = false;
    },
    [updateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload;
      state.loading = false;
    },
    [updateUser.rejected]: (state, action) => {
      state.error = "Ekki tókst uppfæra notanda";
      state.loading = false;
    },
  },
});

const { reducer } = usersSlice;
export default reducer;