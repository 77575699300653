import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SearchSelect from '../search_select';

function FrontpageSearch() {

    const tireParams = useSelector((state) => state.tires.params || { 'width': [], 'aspect_ratio': [], 'diameter': [] });

    const [selectedWidth, setSelectedWidth] = useState("");
    const [selectedAspectRatio, setSelectedAspectRatio] = useState("");
    const [selectedDiameter, setSelectedDiameter] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = new URL(window.location.origin + window.PAGES.webShopPageUrl);
        if (selectedWidth !== "") {
            url.searchParams.append('breidd', selectedWidth);
        }
        if (selectedAspectRatio !== "") {
            url.searchParams.append('profill', selectedAspectRatio);
        }
        if (selectedDiameter !== "") {
            url.searchParams.append('felgustaerd', selectedDiameter);
        }
        window.location.href = url;
    };

    return (
        <form onSubmit={handleSubmit} className="container-fluid mt-md-5 p-md-0">
            <div className="row flex-wrap flex-md-nowrap">
                <div className="col min-w-50">
                    <SearchSelect label="Breidd:" value={selectedWidth} options={tireParams.width} callback={setSelectedWidth} />
                </div>
                <div className="col min-w-50">
                    <SearchSelect label="Prófíll:" value={selectedAspectRatio} options={tireParams.aspect_ratio} callback={setSelectedAspectRatio} />
                </div>
                <div className="col">
                    <SearchSelect label="Felgustærð:" value={selectedDiameter} options={tireParams.diameter} callback={setSelectedDiameter} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <button type="submit" className="btn btn-primary w-100">Leita</button>
                </div>
            </div>
        </form>
    );
}

export default FrontpageSearch;