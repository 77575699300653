import axios from "axios";
import { getCookie } from "../../utils";
import { API_BASE_PATH } from "../../const";

const getCurrentUser = (args) => {
    return axios
        .get(API_BASE_PATH + `users/current`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const updateUser = (args) => {
    return axios
        .put(API_BASE_PATH + `users/${args.id}/`, {
            ...args
        }, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const userService = {
    getCurrentUser,
    updateUser
};

export default userService;