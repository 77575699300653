import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTire } from '../../slices/tires';
import ProductCartAdder from '../product_cart_adder';
import { SVGWinter, SVGSummer, SVGAllYear, SVGBanner } from '../svg'; 

function ProductCard({ product }) {

    const dispatch = useDispatch()

    const setProductAsSelected = (product) => {
        dispatch(setSelectedTire(product))
    }

    return (
        <div className="card mb-4 h-100">
            {product.inventory <= 8 && product.inventory > 0 && <SVGBanner className="card-few-left" />}
            <div className="card-body d-flex flex-column ">
                <div onClick={()=>setProductAsSelected(product)}>
                    <div className='tire-type'>{product.group && product.group.title === 'Vetrardekk' && <SVGWinter />}</div>
                    <div className='tire-type'>{product.group && product.group.title === 'Sumardekk' && <SVGSummer />}</div>
                    <div className='tire-type'>{product.group && product.group.title === 'Heilsársdekk' && <SVGAllYear />}</div>
                    <div className='img-container'>
                        <img src={product.card_image} className="card-img-top mx-auto" alt="..." />
                    </div>
                    <h5 className="card-title">{product.title}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{product.width}/{product.aspect_ratio}R{product.diameter}</h6>
                    {product.description &&
                        <p className='description'></p>
                    }
                </div>
                <ProductCartAdder product={product} />
            </div>
        </div>
    )
}

export default ProductCard;
