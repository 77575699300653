import React from "react";

export const SVGUser = () => {
    return (
        <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <path fill="#5E6F7C" d="M11 0a6 6 0 0 1 2.759 11.33C18.499 12.48 22 16.531 22 21.352c0 .357-.308.647-.688.647-.38 0-.687-.29-.687-.647 0-5.003-4.31-9.059-9.625-9.059-5.316 0-9.625 4.056-9.625 9.059 0 .357-.308.647-.688.647-.38 0-.687-.29-.687-.647 0-4.821 3.501-8.872 8.24-10.025A6 6 0 0 1 11 0Zm0 1.333a4.667 4.667 0 1 0 0 9.334 4.667 4.667 0 0 0 0-9.334Z" />
        </svg>
    );
}

export const SVGCart = () => {
    return (
        <svg width="23" height="26" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 26">
            <path fill="#5E6F7C" d="M17.223 13.204 6.829 14.855c-.304.042-.533.372-.476.702.038.31.286.536.571.536h.077l10.392-1.65c1.79-.29 3.198-1.693 3.617-3.59l1.313-5.945a.682.682 0 0 0-.114-.537.561.561 0 0 0-.457-.227H3.194C2.68 1.585 1.252.204 1.196.141c-.248-.207-.61-.186-.8.062-.21.268-.19.66.038.867.02.02 1.37 1.341 1.713 3.755l1.656 11.867c.19 1.321 1.218 2.292 2.455 2.292h11.306c.325 0 .571-.27.571-.62s-.246-.619-.57-.619H6.257c-.666 0-1.218-.516-1.313-1.238L3.384 5.362h17.644l-1.141 5.18c-.305 1.404-1.351 2.456-2.664 2.662ZM5.003 23.318C5.003 24.804 6.106 26 7.476 26c1.371 0 2.475-1.196 2.475-2.682 0-1.487-1.104-2.684-2.475-2.684-1.37 0-2.473 1.197-2.473 2.684Zm3.806 0c0 .804-.59 1.444-1.333 1.444-.742 0-1.332-.64-1.332-1.444 0-.806.59-1.445 1.332-1.445.743 0 1.333.64 1.333 1.445Zm6.852-2.684c-1.37 0-2.475 1.197-2.475 2.684 0 1.486 1.105 2.682 2.475 2.682 1.371 0 2.474-1.196 2.474-2.682 0-1.487-1.103-2.684-2.474-2.684Zm0 4.128c-.742 0-1.332-.64-1.332-1.444 0-.806.59-1.445 1.332-1.445.743 0 1.333.64 1.333 1.445 0 .804-.59 1.444-1.333 1.444Z" />
        </svg>
    );
}

export const SVGUserInfo = () => {
    return (
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path fill="#5E6F7C" fillRule="evenodd" d="M10 0a5.455 5.455 0 0 1 2.508 10.3C16.818 11.346 20 15.03 20 19.412c0 .325-.28.588-.625.588s-.625-.263-.625-.588c0-4.548-3.918-8.236-8.75-8.236s-8.75 3.688-8.75 8.236c0 .325-.28.588-.625.588S0 19.737 0 19.412c0-4.383 3.183-8.066 7.492-9.113A5.455 5.455 0 0 1 10 0Zm0 1.212a4.242 4.242 0 1 0 0 8.485 4.242 4.242 0 0 0 0-8.485Zm0 3.333c.251 0 .455.204.455.455v2.727a.455.455 0 0 1-.91 0V5c0-.251.204-.455.455-.455Zm0-1.818a.682.682 0 1 1 0 1.364.682.682 0 0 1 0-1.364Z" />
        </svg>
    );
}

export const SVGOrders = () => {
    return (
        <svg width="20" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18">
            <path fill="#5E6F7C" d="M9.483 0 20 2.672v9.577L9.417 18 .004 12.231V2.683L0 2.673l.004-.016.022.007 5.477-1.543.008-.025.04.012L9.483 0ZM1.022 4.036v7.606l7.958 4.877V6.359L1.023 4.036Zm17.96-.02L9.998 6.339v10.164l8.982-4.88V4.016ZM1.684 3.148l7.754 2.263 3.933-1.018L5.664 2.23l-.243-.076-3.736.993Zm16.237.068L9.497 1.071l-2.974.717 8.067 2.278 3.332-.85Z" />
        </svg>
    );
}

export const SVGInvoice = () => {
    return (
        <svg width="15" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 22">
            <path fill="#5E6F7C" d="M10.132 0c.534 0 1.046.237 1.42.657l2.876 3.18c.367.412.572.966.572 1.543l-.062 15.52-.994 1.1-.996-1.1-1.151 1.1-1.065-1.1-1.05 1.1-1.077-1.1L7.538 22 6.45 20.9 5.353 22l-1.05-1.1-1.07 1.1-1.08-1.1L1.092 22 0 20.957.002 2.2C.002.985.893 0 1.993 0h8.14Zm0 1.1H1.993c-.55 0-.995.492-.995 1.1v18.7l1.155-1.1 1.08 1.1 1.07-1.1 1.045 1.1 1.105-1.1 1.065 1.1 1.087-1.1 1.065 1.1 1.062-1.1 1.065 1.1 1.15-1.1.997 1.1V5.38c0-.288-.103-.566-.286-.771l-2.816-3.18a.951.951 0 0 0-.71-.329Zm2.433 14.3v1.1H7.208v-1.1h5.357ZM9.351 5.5v1.1H2.38V5.5h6.971Zm3.214 0v1.1h-2.143V5.5h2.143ZM9.351 7.7v1.1H2.38V7.7h6.971Zm3.214 0v1.1h-2.143V7.7h2.143ZM9.351 9.9V11H2.38V9.9h6.971Zm3.214 0V11h-2.143V9.9h2.143Zm-3.214 2.2v1.1H2.38v-1.1h6.971Zm3.214 0v1.1h-2.143v-1.1h2.143Z" />
        </svg>
    );
}

export const SVGRecycleBin = () => {
    return (
        <svg width="17" height="23" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 23">
            <g fill="none" fillRule="evenodd" stroke="#5E6F7C" strokeWidth=".9" transform="translate(0 .662)">
                <path d="M14.76 5.873V18.99a2.247 2.247 0 0 1-2.249 2.251H4.489a2.243 2.243 0 0 1-1.59-.66 2.243 2.243 0 0 1-.66-1.59V5.872h12.522Z" />
                <path strokeLinecap="square" d="M11.184 17.623V9.489M8.5 17.623V9.489m-2.684 8.134V9.489" />
                <rect width="16.1" height="2.715" x=".45" y="3.161" rx=".9" />
                <path d="M11.632 3.163C11.632 1.416 10.23 0 8.5 0 6.77 0 5.368 1.416 5.368 3.163" />
            </g>
        </svg>
    );
}

export const SVGPdf = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 30" width="23px" height="30px">
            <path fill="#5E6F7C" d="M15.535 0c.819 0 1.604.323 2.178.896l4.41 4.337A2.97 2.97 0 0 1 23 7.337L22.907 27c0 1.657-1.367 3-3.054 3H3.054C1.367 30 0 28.657 0 27V3c0-1.657 1.367-3 3.054-3h12.481Zm0 1.5H3.055c-.844 0-1.528.672-1.528 1.5v24c0 .828.684 1.5 1.527 1.5h16.8c.843 0 1.526-.672 1.526-1.5V7.337c0-.394-.157-.772-.438-1.052l-4.318-4.337a1.541 1.541 0 0 0-1.089-.448Zm1.264 21V24H6.109v-1.5h10.69ZM10.69 9h1.527l-.007 8.902 2.628-2.575 1.08 1.062-4.354 4.271-4.362-4.28 1.08-1.06 2.4 2.356L10.691 9Z" />
        </svg>
    );
}

export const SVGCloseModal = () => {
    return (
        <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="MODAL-VÖRUSPJALD" transform="translate(-1487, -67)">
                    <g id="Close" transform="translate(1487, 67)">
                        <circle id="Oval" fill="#5E6F7C" cx="24" cy="24" r="24"></circle>
                        <polygon id="Shape" fill="#FFFFFF" fillRule="nonzero" points="30.6465209 16.6463723 31.3534791 17.3536277 24.7031167 24 31.3534791 30.6463723 30.6465209 31.3536277 23.9971167 24.706 17.3475958 31.3536277 16.6406376 30.6463723 23.2901167 24 16.6406376 17.3536277 17.3475958 16.6463723 23.9971167 23.293"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export const SVGArrowHead = () => {
    return (
        <svg width="25px" height="40px" viewBox="0 0 25 40" version="1.1">
            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="MODAL-VÖRUSPJALD" transform="translate(-1549, -378)" fill="#47535D" fillRule="nonzero">
                    <path d="M1578.33929,385.836188 L1581.31247,388.512047 C1581.23594,388.597081 1581.16722,388.673438 1581.10153,388.746431 L1581.02384,388.832747 C1580.99823,388.861207 1580.97279,388.889469 1580.94723,388.917873 L1580.86985,389.003849 C1580.42574,389.497308 1579.85634,390.129967 1577.65832,392.572218 L1577.25322,393.022328 C1577.11235,393.178853 1576.96553,393.341979 1576.81247,393.512047 C1571.56247,399.34538 1571.56247,399.34538 1563.31247,408.512047 L1561.82588,410.163812 L1560.33929,408.512047 C1552.08929,399.34538 1552.08929,399.34538 1546.83929,393.512047 C1546.68623,393.341979 1546.53942,393.178853 1546.39855,393.022328 L1545.99345,392.572218 C1543.79542,390.129967 1543.22603,389.497308 1542.78192,389.003849 L1542.70454,388.917873 C1542.67897,388.889469 1542.65354,388.861207 1542.62792,388.832747 L1542.55024,388.746431 C1542.48455,388.673438 1542.41582,388.597081 1542.33929,388.512047 L1545.31247,385.836188 C1545.389,385.921222 1545.45772,385.997579 1545.52342,386.070572 L1545.6011,386.156888 C1545.62671,386.185348 1545.65215,386.21361 1545.67771,386.242014 L1545.75509,386.32799 C1546.19921,386.821449 1546.7686,387.454108 1548.96662,389.896359 L1549.81247,390.836188 C1551.58939,392.810547 1552.7649,394.116662 1553.86241,395.336124 L1554.1602,395.666994 L1554.45647,395.996188 C1556.13555,397.861829 1557.85567,399.773072 1561.54893,403.876698 L1561.82588,404.183118 L1562.10284,403.876698 C1565.7961,399.773072 1567.51622,397.861829 1569.19529,395.996188 L1569.49157,395.666994 L1569.78935,395.336124 C1570.88687,394.116662 1572.06237,392.810547 1573.83929,390.836188 L1574.68514,389.896359 C1576.88317,387.454108 1577.45256,386.821449 1577.89667,386.32799 L1577.97405,386.242014 C1577.99961,386.21361 1578.02505,386.185348 1578.05066,386.156888 L1578.12835,386.070572 C1578.19404,385.997579 1578.26276,385.921222 1578.33929,385.836188 Z" id="Arrow-right" transform="translate(1561.8259, 398) rotate(-90) translate(-1561.8259, -398)"></path>
                </g>
            </g>
        </svg>
    );
}

export const SVGArrowBack = () => {
    return (
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 18 11">
            <polygon id="Arrow" fillRule="evenodd" clipRule="evenodd" fill="#47535D" points="8.5,0.6 8.5,5 18,5 18,6 8.5,6 8.5,10.4 0.8,5.5 " />
        </svg>
    );
}

export const SVGInfo = ({ width, height, color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 55 55" width={width} height={height}>
            <path fill={color} d="M27.5 36c.8 0 1.5-.7 1.5-1.5v-21c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v21c0 .8.7 1.5 1.5 1.5z" />
            <path fill={color} d="M27.5 0C12.3 0 0 12.3 0 27.5S12.3 55 27.5 55 55 42.7 55 27.5 42.7 0 27.5 0zm0 52C14 52 3 41 3 27.5S14 3 27.5 3 52 14 52 27.5 41 52 27.5 52z" />
            <path fill={color} d="M27.5 39c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
    )
}

export const SVGMinus = () => {
    return (
        <svg width="11px" height="3px" viewBox="0 0 11 3" version="1.1">
            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Frontpage-2-B" transform="translate(-644, -1500)" fill="#47535D">
                    <g id="Vöruspjald-93%" transform="translate(615, 1129)">
                        <g id="Counter" transform="translate(18.6, 349.68)">
                            <polygon id="Rectangle-Copy-6" transform="translate(15.81, 22.785) rotate(-90) translate(-15.81, -22.785)" points="14.88 17.67 16.74 17.67 16.74 27.9 14.88 27.9"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const SVGPlus = () => {
    return (
        <svg width="11px" height="10px" viewBox="0 0 11 10" version="1.1">
            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Frontpage-2-B" transform="translate(-733, -1496)" fill="#47535D">
                    <g id="Vöruspjald-93%" transform="translate(615, 1129)">
                        <g id="Counter" transform="translate(18.6, 349.68)">
                            <path d="M105.833277,17.67 L105.83243,21.577 L109.74031,21.5770333 L109.74031,23.1398467 L105.83243,23.139 L105.833277,27.04688 L104.270463,27.04688 L104.27043,23.139 L100.36343,23.1398467 L100.36343,21.5770333 L104.27043,21.577 L104.270463,17.67 L105.833277,17.67 Z" id="Combined-Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const SVGWinter = () => {
    return (
        <svg width="31px" height="31px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
            <path fill="#5E6F7C" d="M16.076 17.493v6.678l3.012 3.013-1.23 1.23-.614-.615-1.168-1.168v3.619h-1.74v-3.532l-1.695 1.696-1.23-1.23.615-.615 2.31-2.311v-6.766a2.604 2.604 0 0 0 1.74 0Zm-3.209-1.304a2.62 2.62 0 0 0 1.246 1.213l-4.58 4.58v4.138h-1.74v-2.4l-2.772 2.775-1.23-1.23 2.835-2.835H4.105v-1.739h4.26l4.502-4.502Zm4.656.043L21.99 20.7h4.137v1.739H23.73l2.773 2.773-1.23 1.23-2.835-2.835v2.521H20.7v-4.26l-4.444-4.445a2.62 2.62 0 0 0 1.268-1.19Zm-14.207-4.82.615.614 2.31 2.31h6.45a2.611 2.611 0 0 0 .124 1.74H6.328l-3.012 3.012-1.23-1.23.615-.614 1.167-1.168H.25v-1.74h3.531l-1.695-1.695 1.23-1.23Zm23.478 0 1.23 1.23-.615.614-1.081 1.08h3.922v1.74h-4.009l1.168 1.168.615.615-1.23 1.23-3.013-3.013h-6.183a2.6 2.6 0 0 0 .123-1.74h6.148l2.31-2.31.615-.614ZM5.29 3.998l2.496 2.496.001-1.527v-.87h1.74v4.137l4.48 4.482a2.62 2.62 0 0 0-1.19 1.268l-4.46-4.46-4.259.002v-1.74h2.52L4.06 5.227l1.23-1.23Zm19.715.054 1.23 1.23-2.498 2.496h2.399v1.74h-4.139l-4.42 4.422a2.62 2.62 0 0 0-1.213-1.246l4.343-4.344V4.09h1.74v2.52l2.558-2.558ZM16.076.25v4.008l1.168-1.167.615-.615 1.23 1.23-3.013 3.012v5.854a2.604 2.604 0 0 0-1.74 0V6.63l-2.31-2.31-.614-.614 1.23-1.23.614.615 1.08 1.08V.25h1.74Z" />
        </svg>
    )
}

export const SVGSummer = () => {
    return (
        <svg width="31px" height="31px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
            <path fill="#F89D1E" d="M15.862.875c.48 0 .868.389.868.869v7.245a6.916 6.916 0 0 1 3.395 1.389l5.118-5.12a.869.869 0 0 1 1.23 1.23L21.358 11.6a6.917 6.917 0 0 1 1.422 3.391h7.225a.869.869 0 0 1 0 1.738h-7.218a6.916 6.916 0 0 1-1.4 3.41l5.103 5.103a.869.869 0 0 1-1.229 1.23l-5.1-5.103a6.916 6.916 0 0 1-3.431 1.418v7.218a.869.869 0 0 1-1.738 0V22.78a6.917 6.917 0 0 1-3.392-1.42l-5.113 5.113a.869.869 0 0 1-1.23-1.229l5.12-5.118a6.916 6.916 0 0 1-1.39-3.394H1.745a.869.869 0 0 1 0-1.738h7.251a6.916 6.916 0 0 1 1.41-3.377L5.279 6.488a.869.869 0 1 1 1.229-1.23l5.13 5.131a6.916 6.916 0 0 1 3.355-1.394V1.744c0-.48.39-.869.87-.869Zm.026 9.8a5.213 5.213 0 1 0 0 10.426 5.213 5.213 0 0 0 0-10.425Z" />
        </svg>
    )
}

export const SVGAllYear = () => {
    return (
        <svg width="31px" height="23px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 23">
            <g fill="none">
                <path fill="#F89D1E" d="m5.878.833 5.128 5.112a6.93 6.93 0 0 1 4.249-1.446v1.73c-2.877 0-5.21 2.326-5.21 5.194s2.333 5.194 5.21 5.194v1.73a6.93 6.93 0 0 1-4.285-1.473l-5.11 5.094a.87.87 0 0 1-1.229 0 .864.864 0 0 1 0-1.224l5.117-5.1a6.878 6.878 0 0 1-1.389-3.382h-7.24a.867.867 0 0 1-.869-.865c0-.478.389-.866.868-.866h7.248a6.88 6.88 0 0 1 1.41-3.365L4.65 2.057a.864.864 0 0 1 0-1.224.87.87 0 0 1 1.228 0Z" />
                <path fill="#5E6F7C" d="M18.058 12.889 22 16.818l3.263-.005.867-.001-.002 1.734-.867.001-1.525.002 2.158 2.15.614.613-1.228 1.224-.614-.612-2.22-2.212-.001 1.65-.001.868-1.734.002.006-4.245-4.25-4.236a2.603 2.603 0 0 0 1.592-.862Zm8.737-5.325 1.224 1.228-.612.614-1.079 1.08 3.922.002v1.73h-4.009l1.166 1.17.612.613-1.224 1.228-3.002-3.01h-5.309a2.58 2.58 0 0 0 .124-1.732h5.271l2.304-2.31.612-.613ZM25.012.25l1.228 1.224-2.495 2.487 1.523.003.867.002.003 1.734-.867-.002-3.263-.005-3.877 3.865a2.605 2.605 0 0 0-1.528-.926l4.12-4.108-.006-4.245 1.734.003.001.867.003 1.65L25.012.25Z" />
            </g>
        </svg>
    )
}

export const SVGAllTires = () => {
    return (
        <svg width="29px" height="26px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 26">
            <g fill="none">
                <path fill="#F89D1E" d="M6.191.75c.276 0 .5.136.5.304v2.594l-.003.021c.293.086.563.225.799.407l1.792-1.792a.304.304 0 0 1 .43.43l-1.79 1.79c.264.336.441.743.498 1.188h2.529c.168 0 .304.223.304.5 0 .275-.136.5-.304.5H8.35l-.01-.002a2.425 2.425 0 0 1-.41.803l1.787 1.786a.304.304 0 0 1-.43.43L7.499 7.924a2.425 2.425 0 0 1-.81.416.055.055 0 0 1 .002.021v2.585c0 .168-.224.304-.5.304s-.5-.136-.5-.304V8.417a2.42 2.42 0 0 1-1.187-.497l-1.79 1.789a.304.304 0 0 1-.43-.43l1.792-1.792a2.425 2.425 0 0 1-.407-.799l-.02.003H1.053c-.168 0-.304-.224-.304-.5s.136-.5.304-.5h2.538a2.42 2.42 0 0 1 .494-1.182L2.29 2.714a.304.304 0 0 1 .43-.43L4.516 4.08a2.42 2.42 0 0 1 1.176-.488V1.054c0-.168.223-.304.5-.304Zm-.186 3.43a1.824 1.824 0 1 0 0 3.65 1.824 1.824 0 0 0 0-3.65Z" />
                <path fill="#5E6F7C" d="m27.19 4.807.43.43-.594.594H28.4v1h-1.012l.233.232-.43.43-.663-.662h-2.245l1.225 1.226h1.449v.609h-.84l.971.97-.43.43-.215-.214-.778-.778v.883h-.608V8.465L23.831 7.24l-.001 2.423.662.663-.43.43-.232-.232v.875h-1v-1.237l-.594.594-.43-.43 1.024-1.025V6.935a2.376 2.376 0 0 0 .735.04l-.064-.065a.917.917 0 0 0 .444-.416l.078.077a2.428 2.428 0 0 0-.01-.741h2.153l1.023-1.023Zm-8.217 0 1.024 1.024 2.258-.001a2.41 2.41 0 0 0-.012.722l.073-.073a.917.917 0 0 0 .437.424l-1.605 1.604.001 1.447h-.609v-.838l-.97.97-.43-.43.991-.993h-.882v-.609h1.49l1.224-1.223h-2.328l-.662.662-.43-.43.231-.232H17.9v-1h1.236l-.593-.594.43-.43Zm.69-2.595.874.874v-.84h.61v1.449l1.568 1.568a.917.917 0 0 0-.417.445l-1.56-1.562h-1.492v-.608h.305l.577-.001-.895-.895.43-.43ZM23.832.9v1.011l.232-.232.43.43-.662.662.001 2.191 1.227-1.227V2.244h.61v.882l.895-.895.43.43-.874.874h.84v.609H25.51l-1.547 1.548a.917.917 0 0 0-.425-.437l.078-.077a2.445 2.445 0 0 0-.787.035V3.132L21.807 2.11l.43-.43.594.593V.9h1Z" />
                <path fill="#F89D1E" d="m10.87 16.304 1.795 1.789a2.426 2.426 0 0 1 1.487-.506v.606a1.82 1.82 0 0 0-1.824 1.818 1.82 1.82 0 0 0 1.824 1.817v.606a2.426 2.426 0 0 1-1.5-.516l-1.789 1.783a.305.305 0 0 1-.43 0 .302.302 0 0 1 0-.428l1.79-1.786a2.413 2.413 0 0 1-.404-.793l-.023.004H9.204c-.168 0-.304-.223-.304-.5 0-.276.136-.5.304-.5h2.537c.057-.44.232-.844.493-1.178l-1.794-1.787a.302.302 0 0 1 0-.429.305.305 0 0 1 .43 0Z" />
                <path fill="#5E6F7C" d="m18.19 18.66.43.43-.593.593H19.4v1h-1.01l.015.015.214.215-.428.43-.658-.66-2.24.001 1.218 1.214h1.144l.303-.001v.606h-.304l-.534.001.755.753.215.215-.43.428-.214-.214-.777-.774v.577l-.001.304h-.607l.001-1.485-1.488-1.482a.911.911 0 0 0 .56-.303l.15.151.04-.18a2.409 2.409 0 0 0 .001-.811l1.846-.001.806-.807.214-.215Zm-.623-2.56.43.428-.874.871.533.001h.304v.607h-.303l-1.14-.003-1.359 1.354a.912.912 0 0 0-.535-.324l1.442-1.438-.001-1.486.606.001v.304l.001.577.896-.892Z" />
            </g>
        </svg>
    )
}

export const SVGClearFields = () => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Frontpage-Verzlun-235/50R20" transform="translate(-439, -242)">
                    <g id="Group" transform="translate(439, 242)">
                        <circle id="Oval" fill="#5E6F7C" cx="10" cy="10" r="10"></circle>
                        <polygon id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" points="13.3333333 6 14 6.66666667 13.6666667 7 10.6660626 9.99949663 13.6666667 13 14 13.3333333 13.3333333 14 13 13.6666667 9.99949663 10.6660626 7 13.6666667 6.66666667 14 6 13.3333333 9.33387345 9.99949663 6.33333333 7 6 6.66666667 6.66666667 6 7 6.33333333 9.99949663 9.33387345"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const SVGPaginateNext = () => {
    return (
        <svg width="10px" height="15px" viewBox="0 0 10 15" version="1.1">
            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Reikningar" transform="translate(-1181, -860)" fill="#5E6F7C" fillRule="nonzero">
                    <polygon id="Path-2-Copy-4" points="1182.54137 860.416989 1189.54137 866.916989 1190.16922 867.5 1189.54137 868.083011 1182.54137 874.583011 1181.45863 873.416989 1187.83 867.5 1181.45863 861.583011"></polygon>
                </g>
            </g>
        </svg>
    )
}

export const SVGPaginatePrev = () => {
    return (
        <svg width="10px" height="15px" viewBox="0 0 10 15" version="1.1">
            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Reikningar" transform="translate(-727, -860)" fill="#5E6F7C" fillRule="nonzero">
                    <polygon id="Path-2-Copy-5" transform="translate(732.1861, 867.5) scale(-1, 1) translate(-732.1861, -867.5)" points="728.913509 860.416989 735.913509 866.916989 736.541367 867.5 735.913509 868.083011 728.913509 874.583011 727.830776 873.416989 734.202143 867.5 727.830776 861.583011"></polygon>
                </g>
            </g>
        </svg>
    )
}

export const SVGCloseMenu = () => {
    return (
        <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1">
            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="menu---mobile" transform="translate(-370, -39)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="Loka" transform="translate(370.9101, 39.0601)">
                        <path d="M19.9040151,19.9040151 C19.6697006,20.1383297 19.2898016,20.1383297 19.055487,19.9040151 L0.175735931,1.02426407 C-0.0585786438,0.789949494 -0.0585786438,0.410050506 0.175735931,0.175735931 C0.410050506,-0.0585786438 0.789949494,-0.0585786438 1.02426407,0.175735931 L19.9040151,19.055487 C20.1383297,19.2898016 20.1383297,19.6697006 19.9040151,19.9040151 Z" id="Line-2-Copy"></path>
                        <path d="M0.175735931,19.9040151 C-0.0585786438,19.6697006 -0.0585786438,19.2898016 0.175735931,19.055487 L19.055487,0.175735931 C19.2898016,-0.0585786438 19.6697006,-0.0585786438 19.9040151,0.175735931 C20.1383297,0.410050506 20.1383297,0.789949494 19.9040151,1.02426407 L1.02426407,19.9040151 C0.789949494,20.1383297 0.410050506,20.1383297 0.175735931,19.9040151 Z" id="Line-2-Copy"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const SVGInfoIcon = () => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <g id="Layout-HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Nýskráning-B-villuskilaboð" transform="translate(-322, -329)" fill="#F89D1E" fillRule="nonzero">
                    <path d="M332,329 C337.522847,329 342,333.477153 342,339 C342,344.522847 337.522847,349 332,349 C326.477153,349 322,344.522847 322,339 C322,333.477153 326.477153,329 332,329 Z M332,330.081081 C327.074217,330.081081 323.081081,334.074217 323.081081,339 C323.081081,343.925783 327.074217,347.918919 332,347.918919 C336.925783,347.918919 340.918919,343.925783 340.918919,339 C340.918919,334.074217 336.925783,330.081081 332,330.081081 Z M332.181818,343.181818 C332.58348,343.181818 332.909091,343.507429 332.909091,343.909091 C332.909091,344.310753 332.58348,344.636364 332.181818,344.636364 C331.780157,344.636364 331.454545,344.310753 331.454545,343.909091 C331.454545,343.507429 331.780157,343.181818 332.181818,343.181818 Z M332,333.363636 C332.301246,333.363636 332.545455,333.607845 332.545455,333.909091 L332.545455,341.545455 C332.545455,341.846701 332.301246,342.090909 332,342.090909 C331.698754,342.090909 331.454545,341.846701 331.454545,341.545455 L331.454545,333.909091 C331.454545,333.607845 331.698754,333.363636 332,333.363636 Z" id="Combined-Shape-Copy"></path>
                </g>
            </g>
        </svg>
    )
}

export const SVGBanner = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114 114" width="114px" height="114px" className={className}>
            <path fill="#21637a" fillRule="evenodd" d="m112 0-5 5h7zM5 114v-7l-5 5z" clipRule="evenodd"/>
            <path fill="#5199b2" fillRule="evenodd" d="M0 73 73 0h39L0 112z" clipRule="evenodd"/>
            <path fill="#21637a" d="m18.3 74.2-2.4 2.4-1.2-1.2 2.6-2.7-.9-.8-3.7 3.7 4.9 5 1.1-1.1-1.9-2 2.3-2.4zm.1-4.3 3 6.9 1.1-1.1-.6-1.3 2.2-2.2 1.3.6 1.2-1.2-6.9-3-1.3 1.3zm1.6.3c.1 0 .2.1.3.2.1.1.2.1.4.2.1.1.2.1.3.2s.2.1.3.1l1.7.8-1.6 1.6-.8-1.7c0-.1-.1-.2-.2-.4s-.2-.3-.2-.5c-.1-.2-.2-.4-.2-.5zm-1-1.7v-1.8l-1.1 1.1.3 1.5zm10-1.1-1.2-1.2 2.7-2.6-.9-.9-2.6 2.6-1.1-1.1 2.9-3-.9-.8-4 4 4.9 5 4.1-4.1-.8-.9zm-.054-8.087 1.132-1.13 4.95 4.949-1.132 1.131zM34.7 53.6l2.7 2.7.2.2.2.2c-.1 0-.1 0-.2-.1-.1 0-.2 0-.3-.1h-.2l-4.8-.5-1 1 4.9 4.9 1.1-1.1-2.7-2.7-.2-.2-.2-.2c.1 0 .2 0 .3.1.1 0 .3.1.4.1l4.8.6 1-1-4.9-4.9-1.1 1zm7.1-5.3-.9-.9-4.4 4.4 1 .9 1.6-1.7 4 4.1 1.1-1.1-4-4.1zm1.148-5.613.848-.849.849.849-.849.848zm-1.605 1.556.848-.848.849.848-.849.849zM49 44.2c-.6-.6-1.2-1-1.7-1.1-.6-.2-1.2-.2-1.7.1-.6.2-1.1.6-1.7 1.1-.5.5-.9 1.1-1.1 1.7s-.2 1.2-.1 1.7c.2.6.5 1.2 1.1 1.7.6.6 1.2.9 1.7 1.1.6.2 1.2.2 1.7 0 .6-.2 1.1-.6 1.7-1.1.5-.5.9-1.1 1.1-1.7.2-.6.2-1.2 0-1.7 0-.6-.4-1.2-1-1.8zm-.4 3.6c-.1.3-.3.5-.5.7-.2.2-.5.4-.7.5s-.5.2-.8.2c-.3 0-.5-.1-.8-.2-.3-.1-.5-.3-.8-.6l-.2-.2c-.3-.3-.4-.5-.6-.8s-.2-.5-.2-.7c0-.3 0-.5.2-.8.1-.3.3-.5.5-.7.2-.2.5-.4.7-.5.3-.1.5-.2.8-.2s.5.1.8.2c.3.1.5.3.8.6l.2.2c.3.3.4.5.6.8.1.3.2.5.2.8 0 .2-.1.4-.2.7zm3.5-11.6-1.3 1.3V42l-2.2-2.3-1.1 1.1 4.9 5 1.1-1.1-1.4-1.5.1-1.6 3.6.8 1.3-1.3-4.9-1zm7.2 1L58.1 36l2.6-2.7-.9-.8-2.6 2.6-1.1-1.1 2.9-3-.8-.9-4.1 4.1 4.9 5 4.1-4.2-.8-.8zm5.5-9.5-2.4 2.4-1.2-1.2 2.6-2.7-.8-.9-3.8 3.8 4.9 4.9 1.2-1.1-2-1.9 2.4-2.4zm4-6.4-.9-.9-4.4 4.4.9.9 1.6-1.6 4.1 4 1.1-1.1-4.1-4zm-.166-1.691 1.132-1.131 4.95 4.95-1.132 1.13zM79.3 18.9l1.2-1.2-3-.9c.2-.4.2-.8.1-1.2-.1-.4-.3-.8-.6-1.1-.3-.3-.6-.5-1-.6s-.7-.1-1.1 0-.8.4-1.1.7L71 17.3l4.9 4.9 1.1-1.1-1.8-1.8 1.4-1.4 2.7 1zM75.9 17l-1.5 1.5-1.4-1.4 1.5-1.5c.2-.2.3-.3.5-.3s.3-.1.5 0 .3.1.5.3c.2.2.3.5.4.7s-.3.5-.5.7z"/>
            <path fill="#fff" d="m17.7 74.2-2.4 2.4-1.2-1.3 2.6-2.6-.9-.9-3.7 3.8 4.9 4.9 1.1-1.1-1.9-2 2.3-2.3zm.1-4.3 3 6.9 1.1-1.1-.6-1.3 2.2-2.2 1.3.6 1.2-1.2-6.9-3-1.3 1.3zm1.6.2c.1 0 .2.1.3.2.1.1.2.1.4.2.1.1.2.1.3.2s.2.1.3.1l1.7.8-1.6 1.6-.8-1.8c0-.1-.1-.2-.2-.4s-.2-.3-.2-.5c-.1-.1-.2-.2-.2-.4zm-1-1.7v-1.8l-1.1 1.1.3 1.6zm10-1.1-1.2-1.2 2.7-2.6-.9-.9-2.6 2.6-1.1-1.1 2.9-2.9-.9-.9-4 4.1 4.9 4.9 4.1-4.1-.9-.9zm-.016-8.006 1.132-1.132 4.95 4.95-1.132 1.132zM34.1 53.5l2.7 2.7.2.2.2.2c-.1 0-.1 0-.2-.1-.1 0-.2 0-.3-.1h-.2l-4.7-.6-1 1 4.9 4.9 1.1-1.1-2.7-2.7-.2-.2-.2-.2c.1 0 .2 0 .3.1.1 0 .3.1.4.1l4.8.6 1-1-4.9-4.9-1.2 1.1zm7.1-5.3-.9-.9-4.4 4.4 1 .9 1.6-1.6 4 4 1.1-1.1-4-4zm1.115-5.604.849-.848.848.848-.848.849zm-1.534 1.627.849-.848.848.848-.848.849zM48.4 44.2c-.6-.6-1.2-1-1.7-1.1-.6-.2-1.2-.2-1.7.1-.6.2-1.1.6-1.7 1.1-.5.5-.9 1.1-1.1 1.7s-.2 1.2-.1 1.7c.2.6.5 1.2 1.1 1.7.6.6 1.2.9 1.7 1.1.6.2 1.2.2 1.7 0 .6-.2 1.1-.6 1.7-1.1.5-.5.9-1.1 1.1-1.7.2-.6.2-1.2 0-1.7 0-.7-.4-1.2-1-1.8zm-.4 3.5c-.1.3-.3.5-.5.7-.2.2-.5.4-.7.5s-.5.2-.8.2c-.3 0-.5-.1-.8-.2-.3-.1-.5-.3-.8-.6l-.2-.2c-.3-.3-.4-.5-.6-.8s-.2-.5-.2-.8c0-.3 0-.5.2-.8.1-.3.3-.5.5-.7.2-.2.5-.4.7-.5.3-.1.5-.2.8-.2s.5.1.8.2c.3.1.5.3.8.6l.2.2c.3.3.4.5.6.8.1.3.2.5.2.8 0 .3-.1.6-.2.8zm3.5-11.6-1.3 1.4v4.4L48 39.7l-1.1 1.1 4.9 4.9 1.1-1.1-1.4-1.5.1-1.5 3.6.7 1.3-1.3-4.9-.9zm7.2 1-1.2-1.2 2.6-2.6-.9-.9-2.6 2.6-1.1-1.1 2.9-2.9-.8-.9-4.1 4.1 4.9 4.9 4.1-4.1-.8-.9zm5.5-9.4L61.8 30l-1.2-1.2 2.6-2.6-.8-.9-3.8 3.7 4.9 5 1.2-1.1-2-2 2.4-2.4zm4-6.4-.9-.9-4.4 4.4.9.9 1.6-1.7 4.1 4.1 1.1-1.1-4.1-4.1zm-.128-1.71 1.132-1.132 4.95 4.95-1.132 1.131zM78.7 18.8l1.2-1.2-3-.9c.2-.4.2-.8.1-1.2-.1-.4-.3-.8-.6-1.1-.3-.3-.6-.5-1-.6s-.7-.1-1.1 0-.8.4-1.1.7l-2.8 2.8 4.9 4.9 1.1-1.1-1.8-1.8 1.4-1.4 2.7.9zm-3.4-1.9-1.5 1.5-1.5-1.4 1.5-1.5c.2-.2.3-.3.5-.3s.3-.1.5 0 .3.1.5.3c.2.2.3.5.4.7s-.2.5-.4.7z"/>
        </svg>
    )
}