import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TireList from '../../components/tire_list';
import { getTireBlockTires } from '../../slices/tires';
import ProductDetail from '../../components/product_detail';
import { getDiscounts } from '../../slices/discounts';

export default function TireblockContainer(props) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getDiscounts())
        dispatch(getTireBlockTires(props.filters))
    }, [])

    const tires = useSelector(state => state.tires.tireBlockList)
    const selectedTire = useSelector(state => state.tires.selectedTire)

    function scrollLeft() {
        const tireRow = document.querySelector('.tire-row');
        const card = document.querySelector('.tire-card-container');
        const cardWidth = card ? card.offsetWidth : 300; 
        if (tireRow) {
            tireRow.scrollBy({ left: -cardWidth, behavior: 'smooth' });
            console.log('scrollWidth:', tireRow.scrollWidth);
            console.log('clientWidth:', tireRow.clientWidth);
            console.log('scrollLeft:', tireRow.scrollLeft);
        }
    }
    
    function scrollRight() {
        const tireRow = document.querySelector('.tire-row');
        const card = document.querySelector('.tire-card-container');
        const cardWidth = card ? card.offsetWidth : 300; 
        if (tireRow) {
            tireRow.scrollBy({ left: cardWidth, behavior: 'smooth' });
            console.log('scrollWidth:', tireRow.scrollWidth);
            console.log('clientWidth:', tireRow.clientWidth);
            console.log('scrollLeft:', tireRow.scrollLeft);
        }
    }
    



    return (
        <>
            <div className="row justify-content-center tire-carousel-container">
                <div className='col-xl-12 col-16 position-relative'>
                    {props.title && <h2 className='tire-block-title'>{props.title}</h2>}
                    <div className='prev' onClick={scrollLeft}></div>
                    <div className='row justify-content-xl-center tire-row overflow-scroll flex-nowrap'>
                        <TireList tires={tires} />
                    </div>
                    <div className='next' onClick={scrollRight}></div>
                </div>
            </div>
            {selectedTire && (
                <ProductDetail selectedTire={selectedTire} productList={tires} />
            )}
        </>
    );
} 
