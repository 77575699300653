import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OrdersService from "../../api/orders";
import { getOrderStatusDescriptive } from "../../utils";
import moment from 'moment';


export const getOrders = createAsyncThunk(
    "orders/get",
    async (args, thunkAPI) => {
        try {
            const response = await OrdersService.getOrders(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    orders: null,
    selectedOrder: null,
};

const ordersSlice = createSlice({
    name: "orders",
    initialState,
    extraReducers: {
        [getOrders.pending]: (state, action) => {
            state.loading = true;
        },
        [getOrders.fulfilled]: (state, action) => {
            state.orders = action.payload.map((order) => {
                return {
                    ...order,
                    orderDate: moment(order.orderDate).toDate(),
                    status: getOrderStatusDescriptive(order.status),
                };
            });
            state.loading = false;
        },
        [getOrders.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja pantanir";
            state.loading = false;
        },
    },
});

const { reducer } = ordersSlice;
export default reducer;